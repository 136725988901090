<template>
  <div class="auth-modal">
    <div class="header-wrapper">
      <h3 class="header-title "> {{ _("Видалити кастинг") }}</h3>
      <div class="header-close" @click.prevent='$emit("close")'>
        <app-icon name="icon-close-modal" size="xl" />
      </div>
    </div>
    <div class="content">
      {{ _("Ви впевненні, що хочете видалити кастинг") }}
      {{ `"${project?.name}" ?` }}
    </div>
    <div class="footer-wrapper">
      <div class="action-wrapper">
        <div class="close" @click.prevent='$emit("close")'>{{ _("Відхилити") }}</div>
        <div class="delete" @click="deleteProject(project)">{{ _("Видалити") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deleteProjectAPI,
} from '@requests/services/services'

export default {
  name: 'delete-project-modal',
  props: {
    project: {},
    updatesPreviewProject: null,
    updateProject: null,
    isRedirect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const modalElement = this.$el.closest('.vm--modal');
    if (modalElement) {
      if (805 < window.innerWidth) {
        modalElement.style.width = '440px';
        modalElement.style.borderRadius = '8px';
      } else {
        modalElement.style.width = `${window.innerWidth - 32}px`;
        modalElement.style.borderRadius = '8px';
      }

      window.addEventListener('resize', () => {
        if (470 < window.innerWidth) {
          modalElement.style.width = '440px';
          modalElement.style.borderRadius = '8px';
        } else {
          modalElement.style.width = `${window.innerWidth - 32}px`;
          modalElement.style.borderRadius = '8px';
        }
      });
    }
  },
  methods: {
    async deleteProject(project) {
      if (project) {
        console.log('project', this.isRedirect)
        await deleteProjectAPI.execute({ id: project.id })
          .then(res => {
            this.$toast.success(this._('Кастинг ycпішно видалено”'), {
              position: 'bottom-right',
              duration: 3000,
              dismissible: true,
            });
            if (this.updatesPreviewProject) {
              this.updatesPreviewProject()
            }

            if (this.updateProject) {
              this.updateProject()
            }

            if (this.isRedirect) {
              this.$router.push('/cabinet/client/projects-client');
            }
          })
          .catch(err => {
            this.$toast.error(this._('Щось піщло не так'), {
              position: 'bottom-right',
              duration: 3000,
              dismissible: true,
            });
          })
          .finally(
            this.$emit('close')
          )
      }
    },
  },
}
</script>
<style scoped>
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  color: #0C0C0E;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.auth-modal {
  padding: 24px;
  border-radius: 8px !important;
}

.header-close {
  cursor: pointer;
}

.content {
  padding: 16px 0;
  text-align: start;
  font-size: content;
  color: #333333;
}

.footer-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
}

.action-wrapper {
  display: flex;
  column-gap: 8px;
}

.close {
  width: 106px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F14747;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;

}

.delete {
  background-color: #F14747;
  width: 103px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}

.delete:hover {
  background-color: #EF2A2A;
}

.close {
  color: #EF2A2A;
}
</style>
