<template>
  <d-control-static-multiselect
    v-model="checked"
    label="title"
    :input-label="filter.label"
    :placeholder="filter.label"
    :searchable="true"
    :internal-search="false"
    :options="localOptions"
    :show-no-results="false"
    @search-change="search"
    v-bind="$attrs"
    :rules="rules"
    @input="submit"
  >
    <template v-slot:noResult>{{ _('No elements found. Consider changing the search query.') }}</template>
    <template v-slot:noOptions>{{ _('Type to search.') }}</template>
    <template v-slot:afterList>
      <div v-observe-visibility="setVisibility" v-if="hasNextPage"></div>
    </template>
  </d-control-static-multiselect>
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

export default {
  name: 'filter-autocomplete-multiselect-widget-component',
  props: [
    'value',
    'filter',
    'receive',
    'placeholder',
    'options',
    'parameters',
    'result',
    'rules',
  ],

  data() {
    return {
      paginationIsDisabled: false,
      isLoading: false,
      isPagination: false,
      localOptions: [],
      query: null,
      checked: '',
      isMounted: true,
    }
  },

  created() {
    this.receive()
  },

  computed: {
    hasNextPage() {
      const page = this.result?.pagination?.nextPage
      return page
    },
  },

  watch: {
    result: {
      handler(nval) {
        this.setOptions(nval)
      },
    },

    value: {
      immediate: true,
      handler(nval) {
        this.setInitialValue(nval)
      },
    },
  },

  methods: {
    resetValue() {
      this.value = ''
    },

    setInitialValue(nval) {
      this.checked = this.normalizeFrom(nval)
    },

    submit() {
      this.$nextTick(() => {
        const val = this.normalizeTo(this.checked)
        this.$emit('input', val)
      })
    },

    normalizeTo(value) {
      if (!value) return []
      return value.id
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      /* eslint-disable-next-line */
      let checked = this.localOptions.find(el => el.id == value)
      if (!checked && this.filter.value && this.filter.title) {
        checked = {
          value: this.filter.value,
          title: this.filter.title,
        }
        this.localOptions.push(checked)
      }
      return checked
    },

    setOptions(nval) {
      const items = nval?.items
      if (this.isPagination) {
        this.localOptions.push(...items)
      } else {
        this.localOptions = items || []
      }
      if (this.isMounted) {
        this.setInitialValue(this.value)
        this.isMounted = false
      }
    },

    search(query) {
      this.isPagination = false
      this.query = query
      this.receive({ query })
    },

    setVisibility(reached) {
      if (reached) {
        const { nextPage: page } = this.result.pagination
        this.isPagination = true
        this.receive({ page, query: this.query })
      }
    },
  },
}

</script>
