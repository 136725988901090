export function disableBeforeDate({ date = new Date(), include = true }) {
  return d => d < new Date(date)
    .setHours(include ? 0 : 24, 0, 0, 0);
}
export function disableAfterDate({ date = new Date() }) {
  return d => d > date
}

export function getVideoId(url, autoplay = false) {
  const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const regExpVimeo = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const matchY = url.match(regExpYoutube);
  const matchV = url.match(regExpVimeo);
  const youtubeIdLength = 11;
  let embedUrl;
  let preview;
  if (matchY && matchY[2].length === youtubeIdLength) {
    const id = matchY[2];
    embedUrl = `https://www.youtube.com/embed/${id}?modestbranding=1&rel=0&autoplay=${autoplay ? 1 : 0}&mute=0&iv_load_policy=3`;
    // this.embedUrl = `https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=${autoplay ? 1 : 0}&mute=1"`;
    preview = `https://img.youtube.com/vi/${id}/hq720.jpg`;
    return { embedUrl, preview };
  }
  if (matchV && matchV[1]) {
    const id = matchV[1];
    embedUrl = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&autoplay=${autoplay ? 1 : 0}`;
    preview = `https://vumbnail.com/${id}.jpg`;
    return { embedUrl, preview };
  }
  throw new Error('error parsed link');
}
