<template>
  <div class="checkbox-wrapper">
    <input
        class="inp-cbx"
        id="cbx-46"
        type="checkbox"
        @change="handleChange"
    />
    <label class="cbx" for="cbx-46">
      <span class="checkbox">
        <svg width="12px" height="12px" viewbox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </svg>
      </span>
      <span class="agreement-checkbox_text">
        Я прочитав і приймаю
        <a class="agreement-checkbox_ref" href="/pravyla-i-umovy-vykorystannia-platformy">
          правила користування платформи
        </a>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'agreement-checkbox',
  props: {
    onChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleChange(event) {
      const isChecked = event.target.checked;
      this.onChange(isChecked);
    },
  },
};
</script>
<style scoped>
input[type="checkbox" i] {
  appearance: auto;
  box-sizing: border-box;
  margin: 2px;
  padding: initial;
  border: initial;

  transition: 0.3s all;
}

.agreement-checkbox_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
}

.agreement-checkbox_ref {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;

  color: #0171c2;
}

.checkbox-wrapper {
  margin-top: 6px;
  margin-bottom: 24px;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox {
  min-width: 20px;
  min-height: 20px;
}

.checkbox-wrapper .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  display: flex;
  gap: 8px;
}
.checkbox-wrapper .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease;
}
.checkbox-wrapper .cbx span:first-child svg {
  position: absolute;
  top: 4px;
  left: 3px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #7069A9;
;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper .cbx:hover span:first-child {
  border-color: #7069A9;
}

.checkbox-wrapper .inp-cbx:checked + .cbx span:first-child {
  background: #7069A9;
  border-color: #7069A9;
  animation: wave-46 0.4s ease;
}
.checkbox-wrapper .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}
</style>
