<template>
  <div :class="['welcome', {
    'welcome-open': isOpenModal
  }]">
    <div class="welcome-modal_background"></div>
    <div class="welcome-modal">
      <div class="welcome-modal_header">
        <div class="welcome-modal_header-icon" @click.prevent="closeModal">
          <Cross width="12" height="12"/>
        </div>
      </div>
      <div class="welcome-modal-img">
        <img class="welcome-modal-img-desktop" src="/static/img/welcome-modal-image-girl.png" width="392" height="197" alt="welcome-modal-image-girl"/>
        <img class="welcome-modal-img-mobile" src="/static/img/welcome-modal-image-girl-mob.png" width="276" height="181" alt="welcome-modal-image-girl"/>
      </div>
      <div class="welcome-modal_text">
        <div class="welcome-modal_text-title">
          Вітаємо у CastingOnline!
        </div>
        <div class="welcome-modal_text-subtitle">
          Створи кастинг → Додай в нього акторів, які сподобались → Відправляй запит відразу всім доданим в кастинг кандидатам → Чекай на їх відповідь!
        </div>
      </div>
      <div class="welcome-modal_button">
        <button class="el-btn el-btn--default text" @click.prevent="closeModal">Почати роботу</button>
      </div>
    </div>
  </div>
</template>
<script>
import Cross from '@app/commons/ui-elements/icons/Cross.vue';

export default {
  name: 'welcome-modal',
  components: { Cross },
  data() {
    return {
      isOpenModal: false,
    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const isOpenWelcomeModal = localStorage.getItem('WELCOME_MODAL')

    const isFinishedRegistration = urlParams.get('finishedRegistration')

    if (isFinishedRegistration && !isOpenWelcomeModal) {
      this.isOpenModal = true
      localStorage.setItem('WELCOME_MODAL', 'true')
    }
  },
  methods: {
    closeModal() {
      this.isOpenModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.welcome {
  display: none;

  &-open {
    display: block;
  }
}

.welcome-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  width: 440px;
  height: 516px;

  border-radius: 16px;

  background: white;
  box-shadow: 0px 4px 16px 0px #0C0C0E1A;

  @media (max-width: 480px) {
    width: 324px;
  }

  &_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: 100;

    background: #00000033;

    @media (max-width: 480px) {
      background: #00000059;
      backdrop-filter: blur(7px)
    }
  }

  &_header {
    display: flex;
    justify-content: end;
    padding: 21.5px 21.5px 13.5px 21.5px;

    &-icon {
      height: 12px;

      cursor: pointer;
    }
  }

  &-img {
    padding: 8px 24px;

    &-desktop {
      display: block;

      @media (max-width: 480px) {
        display: none;
      }
    }

    &-mobile {
      display: none;

      @media (max-width: 480px) {
        display: block;
      }
    }
  }

  &_text {
    padding: 16px 24px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &-title {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
      text-align: center;
    }

    &-subtitle {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }
  }

  &_button {
    padding: 24px 0;

    display: flex;
    justify-content: center;

    button {
      width: fit-content;
      height: fit-content;

      padding: 11.5px 16px;

      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;

      color: white;
    }
  }
}

.el-btn {
  &:hover {
    background: #5CA300;
    color: white;
  }
}
</style>
