import { countriesListAPI } from '@requests/services/services'

export const countriesList = {
  namespaced: true,
  state: {
    countriesList: null,
  },
  getters: {
    getCountriesList: state => state.countriesList,
    getCountry: state => value => {
      const { displayName = '' } = (state.countriesList || []).find(list => list.value === value) || {}
      return displayName
    },
  },
  actions: {
    countryAPI({ commit }) {
      return countriesListAPI.execute().then(({ data: { item } }) => {
        commit('setCountriesList', item)
      })
    },
  },
  mutations: {
    // eslint-disable-next-line
    setCountriesList: (state, response) => state.countriesList = response,
  },
}
