<template lang="pug">
  include ../../Bemto
  +b.g-row.--appearance_spaced.--align_end
    +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--2-fhd(v-if="needSearch")
      tag(
        :tag="$options.widgetsMap['search']"
        :parameters="parameters"
        :value="value['query']"
        :disabled="disabled"
        @input="updateValue('query', $event)"
      )
    +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--2-fhd(
      v-for="(filter, key) in filters"
    )
      tag(
        :tag="$options.widgetsMap['ModelChoiceFilter']"
        :available="available"
        :parameters="parameters"
        :value="value[key]"
        :filter-name="key"
        :disabled="disabled"
        :filter="filter"
        @input="updateValue(key, $event)"
      )
    slot
</template>

<script>
import UiMixin from '../mixins/UiMixin'

export default {
  mixins: [UiMixin],

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'needSearch',
  ],
}
</script>
