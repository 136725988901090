<template>
  <div class="confirm-modal">
    <div class="confirm-modal_header">
      <div class="confirm-modal_header-title">Видалити з обраних</div>
      <div class="confirm-modal_header-icon" @click.prevent="close">
        <img src="/static/img/close.svg" alt="close" width="13" height="13"/>
      </div>
    </div>
    <div class="confirm-modal_content">
      {{this.selectedTalents.length > 1 ? `Ви впевненні, що хочете видалити з обраних ${this.selectedTalents.length} акторів?` : `Ви впевненні, що хочете видалити з обраних актора ${this.selectedTalents[0].firstName}?`}}
    </div>
    <div class="confirm-modal_bottom">
      <button class="confirm-modal_bottom-cancel" @click.prevent="close">Відхилити</button>
      <button class="confirm-modal_bottom-delete" @click="confirmHandler">Видалити</button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    promise: {},
    withReject: {
      type: Boolean,
    },
    id: {},
    confirmingText: {},
    selectedTalents: [],
  },
  methods: {
    close() {
      if (this.withReject) {
        this.promise()
      }
      this.$emit('close');
    },
    confirmHandler() {
      this.promise(true)
      this.$toast.error(this._('Акторів видалено з “Обране”'), {
        position: 'bottom-right',
        duration: 3000,
        dismissible: true,
      });
      this.$emit('close');
    },
  },
}
</script>
<style scoped lang="scss">
.confirm-modal {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #E3E3E8;

  max-width: 440px;
  margin: 0 auto;

  &_content {
    padding: 16px 24px;

    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 26px 24px 18px;

    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;

    color: #0C0C0E;

    &-icon {
      cursor: pointer;
    }
  }

  &_bottom {
    display: flex;
    justify-content: end;
    gap: 8px;

    padding: 16px 24px 24px;

    button {
      max-height: 40px;

      font-size: 14px;
      font-weight: 600;
      line-height: 16.8px;

      border-radius: 40px;
    }

    &-cancel {
      padding: 11px 16px 12px 16px;
      gap: 8px;
      border-radius: 50px;

      color: #F14747;
      background-color: white;

      transition: all 0.3s ease;

      &:hover {
        color: #EF2A2A;
      }
    }

    &-delete {
      padding: 8px 16px 8px 16px;
      gap: 8px;
      border-radius: 40px;

      background: #F14747;
      color: white;

      transition: all 0.3s ease;

      &:hover {
        background: #EF2A2A;
      }
    }
  }
}
</style>
