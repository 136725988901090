/* eslint-disable */
import { renderSlim } from '@aspectus/vue-utils'
import { urlGetter } from '@utils/friendly'

const same = x => x

export default {
  name: 'router-parameters-controller',

  props: {
    initial: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: Function,
      default: same,
    },
    to: {
      type: Function,
      default: same,
    },
    baseUrl: {},
  },

  data() {
    return {
      parameters: this.initial,
      friendlyUrl: {
        filters: {},
      },
    }
  },
  mounted() {
    window.addEventListener('popstate', () => {
      this.parameters = this.from({
        ...this.initial,
      })
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parameters = this.from({
          ...this.initial,
        })
      },
    },
  },

  methods: {
    updateUrl(parameters) {
      const query = this.to(parameters)
      const zero = 0
      this.friendlyUrl.filters = query.filters
      const keys = Object.keys(this.friendlyUrl.filters)
      for (let x = 0; x <= keys.length; x++) {
        const f = this.friendlyUrl.filters[keys[x]]
        if (f !== undefined) {
          if (zero === f.length) {
            delete this.friendlyUrl.filters[keys[x]]
          }
        }
      }
      const order = {
        path: [
          'prefix',
        ],
        query: [
          'query',
        ],
      }
      const shift = 1
      const currentPage = Math.floor(
        parameters.offset / parameters.limit
      ) + shift
      const page = currentPage > shift ? currentPage : null
      const fullUrl = urlGetter([this.baseUrl], order, { ...parameters, page })
      history.pushState({ url: fullUrl }, '', fullUrl)
    },

    changeParameters(value) {
      this.parameters = value
      this.updateUrl(this.parameters)
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      parameters: this.parameters,
      changeParameters: this.changeParameters,
      updateUrl: this.updateUrl,
    }), h, 'tag')
  },
}
