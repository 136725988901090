import './public-path'
import { isValidPhoneNumber } from 'libphonenumber-js'

import Vue from 'vue'
import I18n from 'vue-i18n'

import iComponents from '@app'

import { extend } from 'vee-validate'
import { required, min, max, confirmed, email } from 'vee-validate/dist/rules'

import is from 'is_js'
import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'
import '@/js/validation'
import clickOutside from './directives/v-click-outside';

Vue.config.productionTip = false

Vue.use(install)
Vue.use(iComponents)

const { app } = createApp({
  Vue,
  I18n,
})

Vue.directive('click-outside', clickOutside);

Vue.directive('table-wrapper', {
  bind: node => {
    const tables = Array.from(node.querySelectorAll('table'))
    const zero = 0
    if (zero < tables.length) {
      tables.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('table-wrapper')
      })
    }
  },
})
app.$mount('#app')

extend('required', { ...required, message: app._('This field is required.') })
// eslint-disable-next-line prefer-template
extend('min', { ...min, message: (e, a) => app._('Кількість символів має бути не менше') + ' ' + a.length })
// eslint-disable-next-line prefer-template
extend('max', { ...max, message: (e, a) => app._('Make sure the number of characters in this field does not exceed') + ' ' + a.length })
extend('email', { ...email, message: app._('The field must be a valid email address.') });
// extend('email', {
//   validate(value) {
//     return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value)
//   },
//   message() {
//     return app._('The field must be a valid email address.')
//   },
// })
extend('confirmed', { ...confirmed, message: app._('Password mismatch') })
const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
extend('url', {
  validate: value => is.url(value) && urlRegex.test(value),
  message: app._('This filed must be a valid URL'),
})

const MOBILEREG = /^[+() -\d]+$/

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value)
  },
  message: app._('Phone is not valid'),
})

extend('realPhone', {
  validate(value) {
    return isValidPhoneNumber(value)
  },
  computesRequired: true,
  message: app._('Phone is not valid'),
})
