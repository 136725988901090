<template lang="pug">
include ../../Bemto
+b.checkbox-wrapp
  input.hidden-checkbox(
    type='checkbox'
    :name='name'
    :id='name'
    v-model='checked'
    @change="$emit('input', checked)"
  )
  +b.LABEL.fake-checkbox(
    :for='name'
  )
  +b.LABEL.fav-label.text.--size-sm(
    :class="[checked ? 'text--weight-bold' : '']"
    :for='name'
  ) {{ name }}
</template>

<script>
export default {
  name: 'list-bar',
  props: {
    name: {},
    value: {},
  },
  data() {
    return {
      checked: '',
    }
  },
  mounted() {
    this.checked = this.value
  },
}
</script>
