import FileUploader from './FileUploader'
import FilesUploader from './FilesUploader'
import PhotoUploader from './PhotoUploader'

const VideoFilesUploader = () => import('./VideoFilesUploader')

export default function install(Vue) {
  Vue.component(FileUploader.name, FileUploader)
  Vue.component(FilesUploader.name, FilesUploader)
  Vue.component(PhotoUploader.name, PhotoUploader)
  Vue.component('VideoFilesUploader', VideoFilesUploader)
}
