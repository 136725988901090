export const logoClick = () => {
  /* eslint-disable prefer-destructuring */
  // const language = window.language
  // const location = ['/', `/${language}/`]

  const HEADER_LINKS = Array.from(document.getElementsByClassName('header-link'))

  HEADER_LINKS.forEach(menu => {
    if (window.location.href === menu.href) {
      menu.classList.add('is-active')
    }
  })

  // location.forEach(loc => {
  //   if (loc === window.location.pathname) {
  //     HEADER_LINKS.forEach(element => {
  //       element.classList.add('is-active')
  //     })
  //   }
  // })
}
