<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 16 16"
      fill="none"
  >
    <path
        d="M7.62695 5.74193L13.2838 0.085077L15.1694 1.9707L9.51257 7.62755L15.1694 13.2844L13.2838 15.17L7.62695 9.51317L1.9701 15.17L0.0844807 13.2844L5.74134 7.62755L0.0844807 1.9707L1.9701 0.085077L7.62695 5.74193Z"
        fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
  },
};
</script>
