<template lang="pug">
include ../../Bemto
+b.gallery-block
  +e.top
    +e.gallery-label
      +b.P.text.--size-sm.--font-gotham.--color-dark-gray.--weight-bold {{ fieldLabel }}
      +e.photo-counter(
        v-if='fileCounter'
      )
        +b.P.text.--color-white.--size-xs.--weight-bold {{ photo.length + ` / ${maxFiles}` }}
    +e.P.warning-text(
      v-if="errorText"
    ) {{ errorText }}
    +e.P.warning-text(
      v-if='photo.length > maxFiles'
    ) {{ _('Maximum files is') + ' ' + maxFiles }}
  light-box(
    :source='downloadPhotos'
    :style="{width: '100%', height: '100%'}"
  )
    template(v-slot:default='{ show }')
      +b.gallery
        +e.INPUT.file-input(
          multiple
          :name='name'
          :id='name'
          type='file'
          accept='.jpg, .jpeg, .png, .mp4'
          @change='eventHandler'
        )
        +b.uploaded-wrapper(
          v-for="(item, index) in downloadedPhotos"
          :key='`old_${item.id}`'
        )
          +b-context('uploaded-wrapper')
            +e.remove(
              @click='readyRemoveHandler(index, item)'
            )
              +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
            +e.photo.IMG(
              :src='item.imageLinks.base'
              @click='show(index)'
            )
        +b.uploaded-wrapper(
          v-for="(item, index) in photo"
          :key='`new_${index}`'
        )
          +e.remove(
            @click='removeHandler(index)'
          )
            +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
          +e.photo.IMG(
            :src='item.image || item.imageLinks.base'
          )
        +b.LABEL.gallery__fake-file-input(
          :class="{'is-show': (photo.length + downloadedPhotos.length) < maxFiles}"
          :for='name'
        )
          +b.APP-ICON.text(name="icon-plus")
  slot
</template>

<script>
export default {
  name: 'photo-uploader',
  props: {
    setValue: {},
    errorText: String,
    maxFiles: Number,
    fieldLabel: String,
    fileCounter: Boolean,
    name: String,
  },
  data() {
    return {
      downloadedPhotos: [],
      photo: [],
      downloadPhotos: [],
    }
  },
  watch: {
    setValue: {
      handler(newValue) {
        this.downloadedPhotos = newValue
        this.sourceLinks()
      },
    },
  },
  mounted() {
    this.downloadedPhotos = this.setValue || []
    this.sourceLinks()
  },
  methods: {
    sourceLinks() {
      this.downloadedPhotos.forEach(el => {
        this.downloadPhotos.push(el.imageLinks.base)
      })
    },
    eventHandler(event) {
      this.uploadSource(event)
      this.$emit('photo-data', this.photo)
    },
    uploadSource(event) {
      const { ...files } = event.target.files
      Object.entries(files).forEach(file => {
        if (10485760 > file[1].size) {
          const reader = new FileReader()
          reader.onload = () => {
            const uploadedFile = {
              name: file[1].name,
              image: reader.result,
            }
            this.photo.push(uploadedFile)
          }
          reader.readAsDataURL(file[1])
        } else {
          this.$notify(
            {
              title: this._('Error'),
              text: this._('Убедитесь, что размер этого файла не превышает 10Мб'),
              position: 'top middle',
              type: 'error',
            }
          )
        }
      })
    },
    removeHandler(index) {
      this.photo.splice(index, 1)
    },
    readyRemoveHandler(index, item) {
      // this.downloadedPhotos.splice(index, 1)
      this.$emit('remove-photo', item)
    },
  },
}
</script>
