export function headerSticky() {
  const { body } = document;
  const scrollUpClass = 'scroll-up';
  const scrollDownClass = 'scroll-down';
  let lastScroll = 0;
  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;
    if (0 >= currentScroll) {
      body.classList.remove(scrollUpClass);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDownClass)) {
      // down
      body.classList.remove(scrollUpClass);
      body.classList.add(scrollDownClass);
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDownClass)) {
      // up
      body.classList.remove(scrollDownClass);
      body.classList.add(scrollUpClass);
    }
    lastScroll = currentScroll;
  }, { passive: true });
}
