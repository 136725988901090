import DefaultCheckbox from './DefaultCheckbox'
import DefaultRadio from './DefaultRadio'
import RectangleCheckbox from './RectangleCheckbox'
import RectangleRadio from './RectangleRadio'

export default function install(Vue) {
  Vue.component(DefaultCheckbox.name, DefaultCheckbox)
  Vue.component(DefaultRadio.name, DefaultRadio)
  Vue.component(RectangleCheckbox.name, RectangleCheckbox)
  Vue.component(RectangleRadio.name, RectangleRadio)
}
