import { characteristicsAPI } from '@requests/services/services'

export const talentCharacteristics = {
  namespaced: true,
  state: {
    characteristics: [],
  },
  getters: {
    getCharacteristic: state => state.characteristics,
    getSingleCharacteristic(state) {
      return (objectName, value) => {
        if (state.characteristics[objectName]) {
          const { displayName = '' } = state.characteristics[objectName].find(list => list.value === value) || {}
          return displayName
        }
        return ''
      }
    },
    getCurrentCharacteristic: state => (objectName, values) => state.characteristics[objectName].filter(list => values.includes(list.value)),
  },
  actions: {
    characteristicsRequest({ commit }) {
      return characteristicsAPI.execute().then(({ data: { item } }) => {
        commit('setCharacteristicsList', item)
      })
    },
  },
  mutations: {
    // eslint-disable-next-line
    setCharacteristicsList: (state, response) => state.characteristics = response,
  },
}
