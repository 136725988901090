<template lang="pug">
include ../../Bemto
+b.radio-block
  slot
  +b.P.error-static(
    v-if='errorText && !value'
  ) {{ errorText }}
  row(
    :align="'center'"
  )
    cell(
      v-for="(item, index) in radioData"
      :key='item.value'
      :cols="'narrow'"
      :style="{marginRight: '30px'}"
    )
      row(
        :align="['center']"
      )
        cell
          +b-context('radio-block')
            +e.INPUT.hidden-radio(
              type='radio'
              v-model='value'
              :value='item.value'
              :name='name'
              @change="$emit('radio-value', value)"
              :id="name + index"
            )
            +e.LABEL.fake-radio(
              :for="name + index"
            )
        cell
          +b-context('radio-block')
            +e.LABEL.radio-label(
              :for="name + index"
            )
              +b.P.text.--size-sm.--color-black(
                :class="{'text--weight-medium': value === item.value}"
              ) {{ item.displayName }}
</template>

<script>
export default {
  name: 'radio-bar',
  props: {
    setValue: {},
    errorText: String,
    name: String,
    radioData: Array,
  },
  data() {
    return {
      value: null,
    }
  },
  watch: {
    setValue: {
      immediate: true,
      handler(nval) {
        if (undefined !== nval) {
          this.value = nval
        }
      },
    },
  },
}
</script>
