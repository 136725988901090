<template lang="pug">
include ../../Bemto
+b
  +b.panel.panel-wrapper
    row(
      :align="['center']"
      :justify="['between']"
      space='sm'
    )
      cell.header-wrapper
        row(
          :align="['center']"
        )
          +b.div.icon-wrapper
            edit-icon-active(v-if="isActive")
            edit-icon(v-else)
          cell
            router-link(
              :to="{ name: PROJECTS_LINK }"
            )
              +b.P.text.--color-black.--size-sm.--weight-bold(:class="{ 'active-tab': isActive }") {{ _('Редагування анкети') }}
    hr
    +b.list-container
      +b.DIV.list-wrapper(
        v-for='(tab, index) in tabs'
        :key='`${tab.title}_${index}`'
        @click="changeTab(index)"
      )
        router-link.link(
          :to="{ name: PROJECTS_LINK }"
        )
          +b.P.label(
            :class="{'active-label': activeTab === index && isActive}"
          ) {{ tab.title }}
  +b.panel.panel-wrapper-mobile(@click="handleOpen")
    row(
      :align="['center']"
      :justify="['between']"
      space='sm'
    )
      cell
        row(
          :align="['center']"
        )
          +b.div.icon-wrapper
            edit-icon-active(v-if="isActive")
            edit-icon(v-else)
          cell
            router-link(
              :to="{ name: PROJECTS_LINK }"
            )
              +b.P.text.--color-black.--size-sm.--weight-bold(:class="{ 'active-tab': isActive }") {{ _('Редагування анкети') }}
    hr(v-if="isOpen" :style="{marginTop: '12px'}")
    +b.list-container(v-if="isOpen")
      +b.DIV.list-wrapper(
        v-for='(tab, index) in tabs'
        :key='`${tab.title}_${index}`'
        @click="changeTab(index)"
      )
        router-link.link(
          :to="{ name: PROJECTS_LINK }"
        )
          +b.P.label(
            :class="{'active-label': activeTab === index && isActive}"
          ) {{ tab.title }}
</template>

<script>
import Vue from 'vue';

import { ROUTES_NAMES } from '@routes'

import EditIcon from './Icon/EditIcon.vue'
import EditIconActive from './Icon/EditIconActive.vue'

Vue.component('EditIcon', EditIcon);
Vue.component('EditIconActive', EditIconActive);

export default {
  name: 'talents-menu',
  props: {
    externalActiveTab: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditIcon,
    EditIconActive,
  },
  data() {
    return {
      activeTab: this.externalActiveTab, // Initialize with prop value
      projectCount: 0,
      isVisible: true,
      tabs: [
        {
          title: 'General information', // Use plain strings or check localization function definition
        },
        {
          title: 'Visual data',
        },
        {
          title: 'Clothes',
        },
        {
          title: 'Photo & video',
        },
        {
          title: 'Контакти та додаткова інформація',
        },
      ],
      PROJECTS_LINK: ROUTES_NAMES.TALENT_PROFILE,
      isOpen: false,
    };
  },
  watch: {
    externalActiveTab(newVal) {
      this.activeTab = newVal; // Update activeTab when the prop changes
    },
    isActive(newVal) {
      console.log('externalActiveTab', newVal)
      this.isOpen = newVal
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
      this.$emit('tab-changed', index);
    },
    handleOpen() {
      this.isOpen = true
    },
  },
  async created() {
    await this.getCharacteristics();
    await this.getUserData();
  },
};

</script>
<style scoped>
  .list-wrapper {
    padding-top: 19px;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    row-gap: 25.5px;
    font-size: 14px;
    line-height: 21px;
    color: #212121;
  }

  @media (max-width: 760px) {
    .list-wrapper {
      padding-left: 34px;
    }
  }
  .accordion-container {
    margin-top: 16px;
  }
  .panel-wrapper {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 24px 16px 34px;
    box-shadow: none;
  }
  .active-tab {
    color: #5CA300
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

  .active-label {
    font-weight: 600;
  }

  .link {
    cursor: pointer;
    pointer-events: visible;
  }

  .header-wrapper {
    padding-bottom: 12px;
  }

  .list-container {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  hr {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .panel-wrapper-mobile {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 24px 16px;
    box-shadow: none;
  }
  @media (max-width: 500px) {
    .panel-wrapper {
      display: none;
    }
  }
  @media (min-width: 501px) {
    .panel-wrapper-mobile {
      display: none;
    }
  }
</style>
