<template>
  <div class="video-player" v-if="videoSrc">
    <video id="video" :src="videoSrc" preload="metadata"></video>
    <div class="controls">
      <button id="play-pause" class="play"></button>
      <span class="time" id="current-time">0:00</span>
      <div class="progress-container">
        <input type="range" id="progress-bar" min="0" max="100" value="0">
      </div>
      <div class="time">
        <span class="time" id="total-time">0:00</span>
      </div>

      <div class="volume-container" @mouseenter="enterIcon" @mouseleave="leaveIcon">
        <button :class="['volume-button', {
          'volume-button_no-sound': Number(videoVolume) === 0
        }]" id="volume-button" @click="volumeIconClick"></button>
        <div :class="['volume-container_bar', {
          'volume-container_bar-active': isVolumeBarVisible,
          'volume-container_bar-pointer': isVolumeBarVisible || isEnterVolumeIcon
        }]" @mouseenter="enterMouse" @mouseleave="leaveMouse">
          <input type="range" id="volume-bar" min="0" max="1" step="0.01" value="1">
        </div>
      </div>

      <button id="fullscreen-button"></button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'video-block',
  props: ['videoSrc'],
  data() {
    return {
      isVolumeBarVisible: false,
      videoVolume: 100,
      isEnterVolumeIcon: false,
    }
  },
  mounted() {
    const video = document.getElementById('video');
    const playPauseButton = document.getElementById('play-pause');
    const progressBar = document.getElementById('progress-bar');
    const currentTimeDisplay = document.getElementById('current-time');
    const totalTimeDisplay = document.getElementById('total-time');
    const volumeBar = document.getElementById('volume-bar');
    const fullscreenButton = document.getElementById('fullscreen-button');

    playPauseButton.addEventListener('click', () => {
      if (video.paused) {
        video.play();
        playPauseButton.classList.add('play');
      } else {
        video.pause();
        playPauseButton.classList.remove('play');
      }
    });

    playPauseButton.classList.remove('play');

    function formatTime(seconds) {
      if (!seconds) {
        return '0:00'
      }

      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${10 > secs ? `0${ secs}` : secs}`;
    }

    video.addEventListener('click', () => {
      if (video.paused) {
        video.play();
        playPauseButton.classList.add('play');
      } else {
        video.pause();
        playPauseButton.classList.remove('play');
      }
    })

    video.addEventListener('timeupdate', () => {
      const { currentTime } = video;
      const { duration } = video;
      progressBar.value = (currentTime / duration) * 100;
      progressBar.style.setProperty('--range-progress', `${0 === currentTime ? 0 : progressBar.value}%`);
      currentTimeDisplay.textContent = formatTime(currentTime);
      totalTimeDisplay.textContent = formatTime(duration);

      if (currentTime === duration) {
        playPauseButton.classList.remove('play');
      }
    });

    progressBar.addEventListener('input', () => {
      console.log('progressBar.value', progressBar.value)

      video.currentTime = (progressBar.value / 100) * video.duration;
    });

    volumeBar.addEventListener('input', () => {
      video.volume = volumeBar.value
      this.videoVolume = volumeBar.value
      volumeBar.style.setProperty('--range-progress', `${volumeBar.value * 100}%`);
    })

    fullscreenButton.addEventListener('click', () => {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    });

    video.addEventListener('loadedmetadata', () => {
      totalTimeDisplay.textContent = formatTime(video.duration);
    });

    console.log('INITIAL-TRIGGER', video, playPauseButton, this.videoSrc)

    video.play()
    playPauseButton.classList.add('play');
  },
  watch: {
    videoSrc() {
      const progressBar = document.getElementById('progress-bar');
      const volumeBar = document.getElementById('volume-bar');
      const playPauseButton = document.getElementById('play-pause');

      if (progressBar) {
        progressBar.value = 0;
        progressBar.style.setProperty('--range-progress', '0%');
      }
      if (volumeBar) {
        volumeBar.value = 1;
        volumeBar.style.setProperty('--range-progress', '100%');
      }

      playPauseButton.classList.remove('play');
    },
  },
  methods: {
    volumeIconClick() {
      const video = document.getElementById('video');
      const volumeBar = document.getElementById('volume-bar');

      if (this.videoVolume) {
        this.videoVolume = 0
        volumeBar.style.setProperty('--range-progress', `${this.videoVolume * 100}%`);
        video.volume = this.videoVolume
      } else {
        this.videoVolume = 100
        volumeBar.style.setProperty('--range-progress', `${this.videoVolume * 100}%`);
        video.volume = this.videoVolume / 100
      }
    },
    enterMouse() {
      this.isVolumeBarVisible = true
    },
    leaveMouse() {
      this.isVolumeBarVisible = false
    },
    enterIcon() {
      this.isVolumeBarVisible = true
      this.isEnterVolumeIcon = true
    },
    leaveIcon() {
      setTimeout(() => {
        this.isEnterVolumeIcon = false
      }, 300)
      this.isVolumeBarVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
  max-width: 100%;

  max-height: 372px;

  @media (max-width: 600px) {
    height: 203px;
  }
}

video {
  width: 100%;
  border-radius: 15px;

  height: 372px;
  background-color: rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    height: 203px;
  }
}

.time {
  font-family: Inter, sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
}

#play-pause {
  width: 12px;
  height: 12px;
  background: url('/static/img/video-modal/play-icon.png') center/cover;
  border: none;
  cursor: pointer;
}

#play-pause.play {
  background-image: url('/static/img/video-modal/pause-icon.png');
}

.progress-container {
  flex: 1;
}

#progress-bar {
  width: 100%;
}

.progress-container input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  margin: 18px 0;
  background: linear-gradient(to right, #fff 0%, #fff var(--range-progress, 0%), rgba(255, 255, 255, 0.25) var(--range-progress, 0%), rgba(255, 255, 255, 0.25) 100%);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: transparent;
}

input[type=range]::-webkit-slider-thumb {
  cursor: pointer;
  position: relative;
  z-index: 1;
  opacity: 0;
}

input[type=range]::-moz-range-track {
  background: transparent;
}

input[type=range]::-moz-range-progress {
  background-color: #ffffff;
}

input[type=range]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
}

input[type=range]::-ms-fill-lower {
  background: #ffffff;
}

input[type=range]::-ms-fill-upper {
  background: #ffffff;
  opacity: 25%;
}

.time {
  font-size: 14px;
}

#fullscreen-button {
  width: 17px;
  height: 18px;
  background: url('/static/img/video-modal/volume-icon.png') center/cover;
  border: none;
  cursor: pointer;
}

#fullscreen-button {
  background-image: url('/static/img/video-modal/fullscreen-icon.png');
  width: 16px;
  height: 16px;
}

.volume-button {
  background: url('/static/img/video-modal/volume-icon.png') no-repeat center center;
  width: 13px;
  height: 13px;
}

.volume-button_no-sound {
  background: url('/static/img/video-modal/no_sound.png') no-repeat center center;
}

#volume-button {
  width: 16px;
  height: 16px;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.volume-container_bar {
  position: absolute;
  bottom: 50px;
  right: -28px;
  padding: 3px 7px;

  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  transform: rotate(-90deg);

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: all 1s ease;

  cursor: default;
  pointer-events: none;
}

.volume-container_bar-pointer {
  pointer-events: auto;
}

.volume-container_bar-active {
  opacity: 1;
  pointer-events: auto;
}

#volume-bar {
  width: 56px;
}

.volume-container {
  height: 20px;
}

.volume-container input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  margin: 5px 0;
  background: linear-gradient(to right, #fff 0%, #fff var(--range-progress, 100%), rgba(255, 255, 255, 0.25) var(--range-progress, 100%), rgba(255, 255, 255, 0.25) 100%);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
</style>
