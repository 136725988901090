<template lang="pug">
include ../../Bemto
div
  slot(
    :toggle="favoritesEventHandler"
    :is-favorite="isFavorite"
  )
</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'favorites-bar',
  props: ['isVisible', 'id'],
  data() {
    return {
      listName: '',
    }
  },
  computed: {
    ...mapGetters('favoriteList', ['getFavoriteList']),
    ...mapState('favoriteList', ['favoriteListId']),
    isFavorite() {
      return this.favoriteListId.includes(this.id)
    },
  },
  methods: {
    ...mapActions('favoriteList', ['addTalent', 'removeTalent', 'favoriteListRequest']),

    ...mapMutations('favoriteList', [
      'addNewList',
    ]),
    addToFavorite(id) {
      const params = {
        talents: [id],
      }
      this.addTalent(params)
    },
    removeFromFavorite(id) {
      const data = {
        ids: [id],
      }
      this.removeTalent(data)
    },
    setCheckboxValue(id) {
      let status = false
      this.idsList.forEach(el => {
        if (el === id) {
          status = true
        }
      })
      return status
    },
    favoritesEventHandler() {
      if (this.isFavorite) {
        this.removeFromFavorite(this.id)
      } else {
        this.addToFavorite(this.id)
      }
    },
    hideBar() {
      this.$emit('close-bar')
    },
  },
}
</script>
