<template lang="pug">
  include ../../Bemto
  div
    //- +b.ds-panel.--space_lg(v-if="choicesLocal.length > 20")
    //-   +e.element.--offset_bottom
    //-     d-control-static-input(
    //-       v-model='searchValue',
    //-       @input="searchAttrs"
    //-       type="text"
    //-       :placeholder="_('Поиск')"
    //-     )
    div(
      v-if='filter.options[0] && filter.options[0].color'
    )
      +b.checkbox-block(
        v-for="(item, index) in filter.options"
        :style="{marginBottom: '15px'}"
      )
        +e.INPUT.hidden-checkbox(
          type='checkbox'
          :name='filter.name'
          :id='filter.name + index'
          v-model='checked'
          :value='item.value'
          @change='submit'
        )
        +e.wrapper
          +b-context('checkbox-block')
            +e.LABEL.fake-checkbox-color(
              :for='filter.name + index'
              :style="{border: `1px solid ${item.color}`}"
            )
              div.color-checkbox-item(
                v-if='checked.includes(item.value)'
                :style="{backgroundColor: `${item.color}`}"
              )
        +b.LABEL.text.--size-sm.--pointer(
          :class="{'text--weight-bold': checked.includes(item.value)}"
          :for='filter.name + index'
        )
          +b.SPAN {{ item.displayName }}
          +b.SPAN.text.--color-gray.--weight-default.ma--l-2xxs {{ `(${item.countTalents})` }}
    div(
      v-else
    )
      +b.checkbox-label(
        v-for="(item, index) in filter.options"
        :style="{marginBottom: '15px'}"
      )
        +e.INPUT(
          type='checkbox'
          :name='filter.name'
          :id='filter.name + index'
          v-model='checked'
          :value='item.value'
          @change='submit'
        )
        +b.LABEL.text.--size-sm.--pointer(
          :class="{'text--weight-bold': checked.includes(item.value)}"
          :for='filter.name + index'
        )
          +b.SPAN {{ item.displayName }}
          +b.SPAN.text.--color-gray.--weight-default.ma--l-2xxs {{ `(${item.countTalents})` }}
</template>
<script>
export default {
  name: 'CheckboxWidget',

  props: ['value', 'filter'],

  data() {
    console.log('filter CheckboxWidget!!!', this.filter)
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = this.normalize(nval)
      },
    },

    // filter: {
    //   deep: true,
    //   immediate: true,
    //   handler(nval) {
    //     if (nval && nval.values) {
    //       this.choicesLocal = nval.values
    //     }
    //   },
    // },
  },

  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$emit('input', this.checked)
    },

    normalize(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.options
        .filter(el => val.find(v => Number(v) === el.value)).map(el => el.value)
      return checked
    },

    // searchAttrs() {
    //   this.$nextTick(() => {
    //     const choices = JSON.parse(JSON.stringify(this.filter.values))
    //     this.choicesLocal = choices.filter(choice => {
    //       const searchVal = this.searchValue.toLowerCase()
    //       const choiceName = choice.values[0].toLowerCase()
    //       if (choiceName.includes(searchVal)) {
    //         return choice
    //       }
    //       return false
    //     })
    //   })
    // },
  },
}
</script>
<style scoped>
.checkbox-label input[type="checkbox"] {
  appearance: none; /* Remove default styling */
  width: 20px;
  height: 20px;
  border: 1.5px solid #CCCCCC;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #7069A9;
  border: none;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px; /* Adjust size as needed */
  height: 16px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.394 4.33312L6 12.7271L1.606 8.33312L2.66666 7.27246L6 10.6058L13.3333 3.27246L14.394 4.33312Z" fill="white"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
