<template lang="pug">
include ../../Bemto
+b.checkbox-block
  +e.INPUT.hidden-checkbox(
    type='checkbox'
    :name='name'
    :id='name'
    v-model='value'
    @change="change"
  )
  +e.wrapper
    +b-context('checkbox-block')
      +e.LABEL.fake-checkbox(
        :for='name'
      )
  +b.LABEL.text.--size-sm.--pointer(
    :class="{'text--weight-bold': value}"
    :style="{wordBreak: 'break-normal'}"
    :for='name'
  ) {{ message + ' ' }}
    slot
  +e.P.error(
    v-if='error'
  ) {{ error }}
</template>

<script>
export default {
  name: 'checkbox-bar',
  props: {
    setValue: {},
    name: String,
    message: String,
    error: [String, Boolean],
  },
  mounted() {
    this.value = this.setValue
  },
  data() {
    return {
      value: null,
    }
  },
  methods: {
    change() {
      this.$nextTick(() => {
        this.$emit('checkbox-data', this.value)
      })
    },
  },
}
</script>
