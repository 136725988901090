<template lang="pug">
  include ../Bemto
  +b.d-control-input__icon.--prepend(
    @click.prevent='prependIconFunc',
    :class="prependIcon"
  )
</template>
<script>
export default {
  name: 'PrependComponent',

  props: {
    prependIcon: {},
    prependIconFunc: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
