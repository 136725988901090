<template lang="pug">
include ../../Bemto
+b.container
  +b.content
    +b.navigation
      +e
        projects-accordions(
          :isActive="isProjectsActive"
          :accordionsList="accordionsList"
          :projectCount="projectCount"
        )
      +e
        talents-menu(
          :externalActiveTab="currentTab"
          @tab-changed="handleTabChange"
          :isActive="isTalentsActive"
        )

    +e.DIV.wrapper
      router-view(
      :activeTab="currentTab"
      @tab-changed="handleTabChange"
      :projectCount="projectCount"
      :newCount="newCount"
      :archiveCount="archiveCount"
    )
</template>

<script>
import { mapActions } from 'vuex'
import {
  getTalentProjectPreviewListApi,
} from '@requests/services/services'
import ProjectsAccordions from './ProjectsAccordions.vue'
import TalentsMenu from './TalentMenu.vue'

export default {
  name: 'cabinet-talent',
  components: {
    ProjectsAccordions,
    TalentsMenu,
  },

  async mounted() {
    await this.countryAPI()
    await this.projectSelectsRequest()
    this.favoriteListIdRequest()
    this.getAllProjects()
  },
  methods: {
    ...mapActions('countriesList', [
      'countryAPI',
    ]),
    ...mapActions('projectSelects', [
      'projectSelectsRequest',
    ]),
    ...mapActions('favoriteList', [
      'favoriteListIdRequest',
    ]),
    handleTabChange(newTabIndex) {
      this.currentTab = newTabIndex;
    },
    async getAllProjects() {
      await getTalentProjectPreviewListApi.execute().then(res => {
        const { data } = res
        const allProjects = data.items
        const newProjects = allProjects.filter(el => 0 === el.status)
        const archiveProjects = allProjects.filter(el => 1 === el.status)
        this.projectCount = newProjects.length + archiveProjects.length
        this.accordionsList = [
          {
            name: this._('Активні'),
            item: newProjects,
            status: 0,
          },
          {
            name: this._('Завершенні'),
            item: archiveProjects,
            status: 1,
          },
        ]
        this.newCount = newProjects?.length
        this.archiveCount = archiveProjects?.length
      }).catch(err => {
        this.accordionsList = [
          {
            name: 'Активні',
            item: [],
            status: 0,
          },
          {
            name: 'Завершенні',
            item: [],
            status: 1,
          },
        ]
      })
    },
  },
  data() {
    return {
      currentTab: 0,
      projectCount: 0,
      accordionsList: null,
      newCount: 0,
      archiveCount: 0,
    };
  },
  computed: {
    isProjectsActive() {
      return this.$route.fullPath.includes('cabinet/projects');
    },
    isTalentsActive() {
      return this.$route.fullPath.includes('cabinet/profile');
    },
  },
}
</script>
<style scoped>
  .container {
    max-width: 1440px;
    margin: 0 auto
  }

  .content {
    display: flex;
    padding: 24px 32px;
  }

  .navigation {
    width: 307px;
    min-width: 307px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-right: 32px;
  }
  .content__wrapper {
    width: 100%;
  }

  @media (max-width: 780px) {
    .content {
      padding: 16px;
    }

    .navigation {
      margin-right: 16px;
    }
  }

  @media (max-width: 668px) {
    .content {
      flex-direction: column;
    }

    .navigation {
      margin-right: 0;
      margin-bottom: 16px;
      row-gap: 16px;
      width: 100%;
      min-width: 100%;
    }
  }
</style>
