<template>
  <div class="card-payment-type-bar">
    <div class="payment-type-bar">
      <div v-for="paymentType in paymentTypes" :key="paymentType.displayName">
        <div class="payment-type-wrapper" @click="onChange(paymentType.value)">

          <template v-if="currentType ===paymentType.value">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_2012_29878" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_2012_29878)">
                <path d="M9.55123 15.515L18.1897 6.87653C18.3384 6.7277 18.5124 6.6517 18.7117 6.64853C18.9111 6.64537 19.0883 6.72137 19.2435 6.87653C19.3986
              7.0317 19.4762 7.20987 19.4762 7.41103C19.4762 7.61237 19.3986 7.79061 19.2435 7.94578L10.184 17.0208C10.0031 17.2014 9.79223 17.2918 9.55123
              17.2918C9.31023 17.2918 9.09931 17.2014 8.91848 17.0208L4.74348 12.8458C4.59481 12.6969 4.52148 12.5203 4.52348 12.3158C4.52531 12.1114 4.60381
              11.9317 4.75898 11.7765C4.91414 11.6214 5.09231 11.5438 5.29348 11.5438C5.49481 11.5438 5.67306 11.6214 5.82823 11.7765L9.55123 15.515Z" fill="#63B000"/>
              </g>
            </svg>

          </template>
          <div v-else class="empty"></div>
          <p :class="{'is-active': currentType ===paymentType.value}">{{ paymentType.displayName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'payment-type-bar',
  props: ['currentType', 'paymentTypes', 'onChange'],
  computed: {
  },
  data() {
    return {
    }
  },
}
</script>

<style>
.card-payment-type-bar {
  position: relative;
}

.payment-type-bar {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  left: 0;
  top: 10px;
  min-width: 169px;
  border: 1px solid #CCCCCC;
  padding: 8px 16px;
}

.payment-type-wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 10px 0;
  font-size: 14px;
  line-height: 14px;
}

.empty {
  width: 24px;
  height: 22px;
}

.is-active {

}

</style>
<style lang="css">
::v-deep(.tooltip .tooltip-inner) {
  background: transparent !important;
  border: none !important;
  color: #333; /* Example: Change text color */
  box-shadow: none; /* Example: Remove shadow */
  padding: 8px 12px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
}

.tooltip .tooltip-inner {
  background: transparent !important;
  border: none !important;
  color: #333; /* Example: Change text color */
  box-shadow: none; /* Example: Remove shadow */
  padding: 8px 12px; /* Adjust padding */
  font-size: 14px; /* Adjust font size */
}
</style>
