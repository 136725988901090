import { currentUserAPI } from '@requests/services/services'

export const currentUser = {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    getCurrentUser: state => state.currentUser,
  },
  actions: {
    currentUserAPI({ commit }) {
      return currentUserAPI.execute().then(({ data: { item } }) => {
        commit('setCurrentUser', item)
      })
    },
  },
  mutations: {
    // eslint-disable-next-line
    setCurrentUser: (state, response) => state.currentUser = response,
  },
}
