import isShallowEqual from '@wordpress/is-shallow-equal'
import FilterUi from '../Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'needSearch',
    'hasOtherFilter',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },

    filters() {
      return (this.all && this.all.item) ? this.all.item : []
    },
  },

  created() {
    this.receive({ category: this.label })
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },

    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}
