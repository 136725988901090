<template lang="pug">
include ../../Bemto
+b.talent-card
  +e.A(
    :href="talent.link"
  )
    +e.card-avatar(:class="{ 'recent-card-avatar': recently }")
      +b.DIV.favorite-wrapper(v-if="isAdmin || isClient")
        favorites-bar(
          :id='talent.id'
          :key='talent.id'
          v-slot="{ isFavorite, toggle }"
        )
          .card-favorites-bar(
            :class="{ 'is-active': isFavorite }"
          )
            +b.APP-ICON.icon-heart(
              v-if="!isFavorite"
              :size="['xl']"
              name="icon-heart-talent"
              @click.prevent="toggle()"
            )
            +b.APP-ICON.icon-heart(
              v-else
              :size="['xl']"
              name="icon-heart-talent-active"
              @click.prevent="toggle()"
              :class="{ 'heart-active': isFavorite }"
            )
      .media-container(
        v-if="talent && talent?.videos && talent?.videos[0] && talent?.videos[0]?.videoFile"
        :class="{ 'recent-media-container': recently }"
        @mouseenter="playPreview"
        @mouseleave="pausePreview"
      )
        +e.IMG.photo.cover-image(
          :src="talent?.firstPreviewImage?.previewBase || 'https://via.placeholder.com/400'"
          :alt='talent?.firstName'
          :class="{ 'recent-media-image': recently }"
        )
        +b.VIDEO.preview-video(
          ref="previewVideo"
          :src="talent?.videos[0]?.videoFile"
          loop
          muted
          :class="{ 'recent-media-video': recently }"
          )
      template(v-else-if="talent.firstPreviewImage && talent.firstPreviewImage.previewBase")
        +e.PICTURE.main-avatar(
          v-if="talent.firstPreviewImage.previewBase "
          :class="{ 'recent-media-avatar': recently }"
        )
          +e.SOURCE(:srcset="talent.firstPreviewImage.previewWebp")
          +e.IMG.photo(:src="talent.firstPreviewImage.previewBase", :alt='talent.firstName' loading="lazy")
        +e.second-avatar(
          v-if="talent.secondPreviewImage.previewBase "
           :class="{ 'recent-media-avatar-second': recently }"
        )
          picture
            +e.SOURCE(:srcset="talent.secondPreviewImage.previewWebp")
            +e.IMG.photo(:src="talent.secondPreviewImage.previewBase", :alt='talent.firstName' loading="lazy")

      +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName' :class="{ 'recent-media-avatar-second': recently }")
  +e.data.wrapper
    row(
      :justify="'between'"
      :align="'center'"
    )
      cell
        +b.A.text.--weight-bold.--font-serif-.--size-lg-lg.--color-black.--pointer-green.name-text(
          :href="talent.link"
          :class="{'title-ellipsis': !!talent.lastName}"
          :title="talent.lastName ? `${talent.firstName} ${talent.lastName}` : ''"
        ) {{ talent.firstName || '-' }} {{ talent.lastName || ''}}
      cell
        row(
          :align="'center'"
        )
          cell(v-if="isAdmin")
            v-popover(
              :popover-class="'tooltip-modal'"
              :delay="{ show: 500, hide: 500 }"
              :key='talent.id'
            )
              +b.APP-ICON.text.--pointer-green(
                :size="['md', 'xl-md']"
                name="icon-star"
              )
              template(#popover)
                project-bar.card-projects-bar(
                  :key='talent.id'
                  :id='talent.id'
                )
          cell
            modal-trigger(
              name='TalentPreview'
              url='modals/TalentPreview'
              :classes="['vm--modal_xxxl']"
              :talent='talent'
              :isAdmin="isAdmin"
              :projects="projects"
            )
              template(#default='{ open }')
                span(
                  @click.prevent='open'
                  :style="{cursor: 'pointer'}"
                )
                  app-icon(name="icon-play" size="xl")
          cell(v-if="isClient || isAdmin" @click="toggleClick" :style="{marginLeft: '18px', cursor: 'pointer'}")
            +b.DIV( :class="{ 'plus-active': isBarOpen }")
            v-popover(
              :popover-class="'tooltip-modal'"
              :delay="{ show: 100, hide: 100 }"
              :key='talent.id'
            )

              app-icon(v-if="!isBarOpen" name="icon-plus-inactive" size="xl")
              app-icon(v-else name="icon-plus-active" size="xl")
              template(#popover)
                casting-bar.card-casting-bar(
                  :id='talent.id'
                  :key='talent.id'
                  :projects='projects'
                )
    row
      cell
        +b.P.text.--size-sm.--size-md-lg.text {{ _('ID: ') + talent.id }}
      cell
        +b.delimiter
      cell
        +b.P.text.--size-sm.--size-md-lg.text {{ _('Age: ') + talent.age }}
    row.card-data.card-tags(
      appearance="spaced"
      space="12px"
      :align="'center'"
    )
      cell(
        v-for="(tag, index) in talent.specials"
        :key='`specials_${tag}_${index}`'
      )
        +b.SPAN.card-tag.text.--size-2xxs.--size-xs-md(
          v-if="characteristic"
        ) {{ characteristic('specials', tag) }}
      cell(
        v-for="(tag, index) in talent.skills"
        :key='`skills_${tag}_${index}`'
      )
        +b.SPAN.card-tag.text.--size-2xxs.--size-xs-md(
          v-if="characteristic"
        ) {{ characteristic('skills', tag) }}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'talent-card',
  props: {
    talent: Object,
    projects: Array,
    recently: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['isAdmin'],
  data() {
    console.log('talent', this.talent)
    return {
      starActive: false,
      hover: false,
      isClient: 'client' === window?.ROLE,
      isBarOpen: false,
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getSingleCharacteristic',
    }),
  },
  methods: {
    toggleClick() {
      this.isBarOpen = !this.isBarOpen;
    },
    playPreview() {
      const video = this.$refs.previewVideo;
      if (video) {
        video.play();
      }
    },
    pausePreview() {
      const video = this.$refs.previewVideo;
      if (video) {
        video.pause();
        video.currentTime = 0; // Reset to the beginning
      }
    },
  },
}
</script>
<style scoped>
  .plus-active {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: #F2F2F2;
    border-radius: 8px;
    top: -13px;
    right: -7px;
  }

  .favorite-wrapper {
    position: absolute;
    top: 18px;
    right: 24px;
    z-index: 1;
  }
  .heart-active {
    position: relative;
  }
  .heart-active::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #FFFFFF66;
    border-radius: 4px; /* Makes it circular */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the pseudo-element */
    z-index: -1; /* Ensures it appears behind the heart icon */
  }

  .icon-heart {
    transition: all 0.3;
  }
  .icon-heart:hover .ds-inliner__body {
    width: 32px;
    height: 32px;
  }

  .icon-heart:hover .ds-inliner__body > svg {
    stroke-width: 2px;
  }

  .wrapper {
    min-height: 100%;
  }

  @media (min-width: 520px) {
    .wrapper {
      min-height: 195px;
    }
  }
  .media-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 195px;
    max-height: 195px;
  }

  .preview-video {
    width: 100%;
    height: 100%;
    display: none; /* Hidden by default */
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 8px;
  }

  .media-container:hover .preview-video {
    display: block; /* Show video on hover */
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .card-tag {
    max-height: 24px;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    min-height: 24px;
  }
  .name-text {
    font-size: 20px;
    line-height: 26px;
    color: #000000;
  }

  .recent-media-container {
    width: 348px;
  }
  .recent-media-avatar {
    width: 348px;
  }

  .recent-card-avatar {
    width: 348px;

  }

  .title-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 245px;
    position: relative;
    cursor: pointer;
    line-height: 26px;
  }
</style>
