import Bar from './bar'
import Inputs from './inputs'
import Cards from './cards'
import UiElements from './ui-elements'
import Uploads from './uploads'

export default function install(Vue) {
  Vue.use(Bar)
  Vue.use(Cards)
  Vue.use(UiElements)
  Vue.use(Uploads)
  Vue.use(Inputs)
}
