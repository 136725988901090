<template>
  <filter-controller
    :receive="allProps.receive"
    :all="allProps.result"
    :loading="allProps.loading"
    :allAdditional="additionalProps.result"
    :additionalResource="additionalResource"
    :pagination='pagination'
    :totalItems="totalItems"
    :value="value"
    :label="label"
    @input="handleFilter"
    @get:filters:list="$emit('get:filters:list', $event)"
    :hasOtherFilter="hasOtherFilter"
    :changeFilters="changeFilters"
  />
</template>

<script>

import ViewMixin from './mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
}

</script>
