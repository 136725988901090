import { render, staticRenderFns } from "./CheckboxWidget.vue?vue&type=template&id=3e2e920c&scoped=true&lang=pug&"
import script from "./CheckboxWidget.vue?vue&type=script&lang=js&"
export * from "./CheckboxWidget.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxWidget.vue?vue&type=style&index=0&id=3e2e920c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2e920c",
  null
  
)

export default component.exports