<template>
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2291_34776" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_2291_34776)">
      <path d="M8.52373 18.11L12.4016 15.771L16.2795 18.1408L15.2639 13.7089L18.6801 10.7543L14.1867 10.3542L12.4016 6.16855L10.6166 10.3234L6.12313 10.7235L9.53937 13.7089L8.52373 18.11ZM12.4016
      17.2199L7.93189 19.9188C7.80078 19.9834 7.67921 20.0095 7.56719 19.997C7.45516 19.9842 7.34631 19.9456 7.24064 19.8809C7.13477 19.8161 7.05424 19.7233 6.99904 19.6027C6.94385 19.4821 6.93913
      19.3515 6.98488 19.2109L8.17349 14.1515L4.24112 10.7402C4.13052 10.6501 4.05748 10.5424 4.02198 10.417C3.98649 10.2914 3.99398 10.1711 4.04445 10.056C4.09493 9.94068 4.16284 9.84671 4.24819 9.77407C4.33334
      9.70164 4.44855 9.65271 4.59382 9.62726L9.78313 9.17515L11.8073 4.38348C11.8625 4.24765 11.9426 4.14978 12.0477 4.08987C12.1528 4.02996 12.2707 4 12.4016 4C12.5325 4 12.6505 4.02996 12.7556 4.08987C12.8606 4.14978 12.9407
        4.24765 12.9959 4.38348L15.0201 9.17515L20.2094 9.62726C20.3547 9.65271 20.4699 9.70164 20.5551 9.77407C20.6404 9.84671 20.7083 9.94068 20.7588 10.056C20.8093 10.1711 20.8168 10.2914 20.7813 10.417C20.7458 10.5424 20.6727
        10.6501 20.5622 10.7402L16.6298 14.1515L17.8184 19.2109C17.8641 19.3515 17.8594 19.4821 17.8042 19.6027C17.749 19.7233 17.6685 19.8161 17.5626 19.8809C17.457 19.9456 17.3481 19.9842 17.2361 19.997C17.1241 20.0095 17.0025
        19.9834 16.8714 19.9188L12.4016 17.2199Z"
        fill='#212121'/>
      </g>
    </svg>
</template>
<script>
export default {
  name: 'star-icon',
}
</script>
