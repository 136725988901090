import { render, staticRenderFns } from "./CabinetProjectBar.vue?vue&type=template&id=bc3e9c52&"
import script from "./CabinetProjectBar.vue?vue&type=script&lang=js&"
export * from "./CabinetProjectBar.vue?vue&type=script&lang=js&"
import style0 from "./CabinetProjectBar.vue?vue&type=style&index=0&id=bc3e9c52&prod&lang=scss&"
import style1 from "./CabinetProjectBar.vue?vue&type=style&index=1&id=bc3e9c52&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports