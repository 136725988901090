<template lang="pug">
include ../Bemto
+b.range-block
  slot
  +e.error {{ errorText }}
  +b.range-wrapper
    range-slider(
      :class="classProp"
      :min='min'
      :max='max'
      v-model='value'
      @change="$emit('range-data', value)"
    )
    +e.range-label {{ value + ' ' + rangeLabel }}
</template>

<script>
export default {
  name: 'registration-range',
  props: {
    setValue: {},
    classProp: String || Array,
    errorText: String,
    rangeLabel: String,
    min: Number,
    max: Number,
  },
  data() {
    return {
      value: 0,
    }
  },
  watch: {
    setValue(newVal) {
      this.value = newVal || 0
    },
  },
  mounted() {
    this.value = this.setValue || 0
  },
}
</script>
