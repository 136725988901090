<template lang="pug">
  include ../Bemto
  +b.ds-panel--space_xl-lg.pagination-wrap(v-if='page && range.max > 1')
    +e.element--offset_top
      row(
        align='center',
      )
        cell(
          cols='12 6-xl'
          v-if='needAddBtns'
        )
          row(
            align='center',
          )
            cell(
            )
              row(align='center')
                cell
                  +e.text.P.pagination__item {{ _("Строк на странице") }}
                cell(
                )
                  +e.select.D-CONTROL-MULTISELECT(
                    style='width: 60px'
                    :options='pageSizes'
                    v-model='pagelimit'
                    @input='changeLimit'
                  )

            cell(
              cols='narrow'
            )
              +e.text.P.pagination__item {{ _("Показано") + " " }}
                | {{ pageObj.from }} {{ _('до') }} {{ page.count < pageObj.to ? page.count : pageObj.to }} {{ _('из') }} {{ page.count }}
            cell(
              cols='narrow'
            )
              template(v-if='page.count <= limit')
                +b.pagination-legend(v-if='show')
                  +b.um-link--variant_start.A.pagination__item(
                    href='',
                    v-if='page.count > page.limit'
                    @click.prevent='showAll(page.count), show=false'
                  ) {{ _('Показать все') }}
                +b.um-link--variant_start.A.pagination__item(
                  href='',
                  v-else
                  @click.prevent='showAll(10), show=true'
                ) {{ _('Сбросить') }}
        cell(
          :cols='needAddBtns ? "12 6-xl" : "12" '
        )
          +b('ul').pagination(v-if='page && range.max > 1')
            +e.LI.item: +e.A.link.--prev(
              href='#',
              @click.prevent='previous()'
              :class='{"is-disabled": !page.hasPrevious()}'
            )
              +b.icon-prev
              +b.P {{ _('Prev') }}
            template(v-if='page.hasPrevious()')
              +e.LI.item: +e.A.link(href='#', @click.prevent='select(range.min)') {{ range.min }}
            +e.LI.item(v-if='gaps.left'): +e.link ...
            +e.LI.item(v-for='page in fills.left'): +e.A.link(href='#', @click.prevent='select(page)') {{ page }}
            +e.LI.item: +e.link.is-active(v-text='page.getPageNumber()')
            +e.LI.item(v-for='page in fills.right'): +e.A.link(href='#', @click.prevent='select(page)') {{ page }}
            +e.LI.item(v-if='gaps.right'): +e.link ...
            template(v-if='page.hasNext()')
              +e.LI.item: +e.A.link(href='#', @click.prevent='select(range.max)') {{ range.max }}
            +e.LI.item: +e.A.link.--next(
              href='#',
              @click.prevent='next()'
              :class='{"is-disabled": !page.hasNext()}'
            )
              +b.icon-next
              +b.P {{ _('Next') }}
        cell(
          cols='12 3-xl'
        )
</template>

<script>
/* eslint-disable prefer-spread */
import { LimitOffsetPagination } from './Pagination'

export default {
  template: '<div></div>',
  name: 'UiLimitPagination',
  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2,
    },
    needAddBtns: {
      type: Boolean,
      default: () => false,
    },
    reset: {},
  },

  data() {
    return {
      pageSizes: [
        10,
        20,
        40,
        60,
        80,
        100,
      ],
      pagelimit: 10,
      page: {},
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
      one: 1,
      zero: 0,
      limit: 200,
      show: true,
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },
  computed: {
    pageObj() {
      const to = this.page.limit + this.page.offset
      const from = this.page.offset + this.one

      return { to, from }
    },
  },
  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
        this.pagelimit = this.pagination.limit
        this.show = true
      },
    },
    page() { this.recalcRange() },
    reset(nval) {
      if (nval) {
        this.show = true
      }
    },
  },

  methods: {
    changeLimit() {
      this.$emit('changeLimit', { limit: this.pagelimit })
    },
    showAll(e) {
      this.$emit('changeLimit', { limit: e })
    },
    recalcRange() {
      const [min, max] = this.page.getPageRange()
      const current = this.page.getPageNumber()
      const [start, end] = this.page.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + this.one })
        .map((x, i) => i + start)
      const currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        const minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        const maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = []
        this.fills.right = this.fills.left
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + this.one
      )
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - this.one] !== max - this.one
      )
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.$emit('input', this.page.getParameters(number))
      this.scrollTop()
    },

    next() {
      this.$emit('input', this.page.next())
      this.scrollTop()
    },

    previous() {
      this.$emit('input', this.page.previous())
      this.scrollTop()
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}

</script>
