import { pipe, partialRight } from 'ramda'

import {
  baseResource as superResource,
  receiveResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'
import superFetcher from '@aspectus/superagent-fetch';

import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
  raiseHttpErrorTransformer,
  simpleTransformer,
  superagentJsonTransformer,
} from './transformer'

import { HEADERS_MIDDLEWERE, multipartRequestMiddleware } from './middleware'

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)

export const postResource = getResource
  .config('method', 'POST')

export const patchResource = postResource
  .config('method', 'PATCH')

export const putResource = postResource
  .config('method', 'PUT')

export const deleteResource = getResource
  .config('method', 'DELETE')

export const baseResource = superResource
  .middleware(HEADERS_MIDDLEWERE)

export const sendFileResource = baseResource
  .fetcher(superFetcher)
  .middleware(multipartRequestMiddleware)
  .transform(superagentJsonTransformer)
  .transform(simpleTransformer)
  .config('method', 'POST')

export function createResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(makeTemplateGetter(template))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}
export const createSimpleResource = (template, base = baseResource) => (
  base.url(makeTemplateGetter(template))
)
export const createFileSender = partialRight(createSimpleResource, [sendFileResource])
