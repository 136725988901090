import { projectSelectsAPI } from '@requests/services/services'

export const projectSelects = {
  namespaced: true,
  state: {
    selects: {},
  },
  getters: {
    getSelectsList: state => state.selects,
    getSingleSelect: state => (objectName, value) => {
      const { displayName = '' } = (state.selects[objectName] || []).find(list => list.value === value) || {}
      return displayName
    },
    getCurrentSelect: state => (objectName, values) => {
      const arr = (state.selects[objectName] || []).filter(list => (values || []).includes(list.value)) || []
      return arr
    },
  },
  actions: {
    projectSelectsRequest({ commit }) {
      return projectSelectsAPI.execute().then(({ data: { item } }) => {
        commit('setSelectsList', item)
      })
    },
  },
  mutations: {
    setSelectsList: (state, response) => {
      state.selects = response
    },
  },
}
