<template lang="pug">
  include ../../Bemto
  div
    //- vue-easy-lightbox(
    //-   :class="'text-box'"
    //-   moveDisabled
    //-   :visible="visible"
    //-   :imgs="source"
    //-   :index="idx"
    //-   @hide="handleHide"
    //- )
    VueLightbox(
      :index="idx"
      @chang="e => idx = e"
      @close="idx = null"
      :class="'text-box'"
      :toolbar="toolbar"
      :items="source"
      :video="{ autoplay: true, width: 'auto', muted: false, }"
    )

    slot(
      :show='src => showImg(src)'
    )

</template>
<script>
// import VueEasyLightbox from 'vue-easy-lightbox'
import VueLightbox from 'vue-lightbox-lite';
import 'vue-lightbox-lite/dist/index.css';

export default {
  name: 'LightBox',
  props: {
    source: {
      type: Array,
    },
  },
  components: {
    // VueEasyLightbox,
    VueLightbox,
  },
  data() {
    this.toolbar = ['counter', 'zoom', 'rotate', 'close']
    return {
      visible: false,
      img: [],
      idx: null,
    }
  },
  methods: {
    showImg(idx) {
      this.idx = idx
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>
