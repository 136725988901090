<template>
  <div :class="['success-payment', {
    'success-payment-open': isOpenModal
  }]">
    <div class="success-payment-modal_background"></div>
    <div class="success-payment-modal">
      <div class="success-payment-modal_header">
        <div class="success-payment-modal_header-icon" @click.prevent="closeModal">
          <Cross width="12" height="12"/>
        </div>
      </div>
      <div class="success-payment-modal_img">
        <img src="/static/img/check_circle.svg" alt="check_circle" width="60" height="60" style="margin: 10px" />
      </div>
      <div class="success-payment-modal_title">
        Успішно сплачено!
      </div>
      <div class="success-payment-modal_subtitle">
        Анкети успішно сплачено. Відкриті анкети можна подивитися у особистому кабінеті в розділі на сторінці кастингу або у “Мої відкриті анкети”.
      </div>
    </div>
  </div>
</template>
<script>
import Cross from '@app/commons/ui-elements/icons/Cross.vue';

export default {
  name: 'success-payment',
  components: { Cross },
  data() {
    return {
      isOpenModal: false,
    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const isSuccessPayment = urlParams.get('successPayment')

    if (isSuccessPayment) {
      this.isOpenModal = true
    }
  },
  methods: {
    closeModal() {
      const urlWithoutQuery = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutQuery);
      this.isOpenModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.success-payment {
  display: none;

  &-open {
    display: block;
  }
}

.success-payment-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  width: 494px;
  height: 273px;

  border-radius: 16px;

  background: white;
  box-shadow: 0px 4px 16px 0px #0C0C0E1A;

  @media (max-width: 480px) {
    width: 324px;
    height: fit-content;
  }

  &_background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    z-index: 100;

    background: #00000033;

    @media (max-width: 480px) {
      background: #00000059;
      backdrop-filter: blur(7px)
    }
  }

  &_header {
    display: flex;
    justify-content: end;
    padding: 21.5px 21.5px 13.5px 21.5px;

    &-icon {
      height: 12px;

      cursor: pointer;
    }
  }

  &_img {
    display: flex;
    justify-content: center;
  }

  &_title {
    margin-top: 16px;

    display: flex;
    justify-content: center;

    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  &_subtitle {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

    padding: 0 40px;
    margin-top: 8px;

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}

.el-btn {
  &:hover {
    background: #5CA300;
    color: white;
  }
}
</style>
