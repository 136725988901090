import BaseView from '@app/List/View'
import { withDefaultProps } from '@aspectus/vue-utils'
import {
  productsListResource,
  productsFilterResource,
} from '@requests/services/catalog'

import RouterParametersController from './RouterParametersController'

const composer = (
  listUrl,
  filtersUrl,
  controllerName = 'Controller',
  filterName = 'DefaultFilter',
  hasOtherFilter = false
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
  hasOtherFilter: () => hasOtherFilter,
})(BaseView)

const CabinetListView = composer(
  productsListResource,
  productsFilterResource,
  'Controller',
  'DefaultFilter',
  true

)

export default function plugin(Vue) {
  Vue.component('talants-list-view', CabinetListView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
