<template lang='pug'>
  include ../../Bemto
  +b.ds-panel.--space_xl(v-if="Object.keys(filters).length")
    +e.element.--offset_bottom
      +b.cabinet-filter
        +e.wrapper
          +b.i-preloader.--variant_1(:class="{'is-active': loading}")
            +e.item.--size_md
          +e.content
            filter-ui(
              :disabled="loading"
              :value="prepared"
              :filters="filters"
              :pagination='pagination'
              :needSearch="needSearch"
              @input="handleInput"
            )
              +b.g-cell.g-cols.--12-xs.--6-sm.--4-md.--3-xl.--2-fhd
                +b.g-row
                  +b.g-cell.g-cols.--auto-xs
                    +b.BUTTON.control-button.--offset-horizontal_sm(@click.prevent="handleInputFilter(prepared)")
                      +e.element
                        +e.SPAN.text {{ _('Фильтровать') }}
                  +b.g-cell
                    +b.BUTTON.control-button.--offset-horizontal_sm(
                      @click.prevent="handleInputFilter({})"
                      :title="_('Очистить фильтр')"
                    )
                      +e.element
                        +e.icon.--size_2md
                          +b.I.icon-clear-filter
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
