import {
  headersMiddleware,
  overrideMiddleware,
  bodyMiddleware,
} from '@aspectus/resource'

import { getCookie } from '@utils/cookies'

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRFToken': getCookie('csrftoken'),
  'Accept-Language': window.language,
}))

export const multipartRequestMiddleware = bodyMiddleware(
  body => Object.keys(body).reduce(
    (acc, x) => {
      const value = Array.isArray(body[x]) ? body[x] : [body[x]];
      value.forEach(v => acc.append(x, v));

      return acc;
    },
    new FormData()
  )
);
