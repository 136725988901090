<template lang="pug">
include ../Bemto
+b.verification-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap
  +e.P.title.text--align-center.--weight-bold.--size-xl.--font-serif.--color-black.title  {{ _("Password creating") }}
  +b.P.subtitle {{ _('Choose security password. We recommend using capital letters and special characters.') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '16px'}"
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|min:8'"
      v-model='form.password'
      type='password'
      name='password'
      :placeholder="_('Password')"
    )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|min:8|confirmed:password'"
      v-model='form.password2'
      type='password'
      name='password2'
      :placeholder="_('Repeat password')"
    )
    +b.BUTTON.el-btn.--default.text--align-center.--space-lg.--color-white.--weight-bold.ma--t-sm.button-wrapper(
      @click.prevent="prepareData(valid)"
    ) {{ _('Створити') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { checkPasswordAPI } from '@requests/services/services'

export default {
  mixins: [
    FormMixin,
  ],
  name: 'password-create',
  data() {
    return {
      form: {
        password: null,
        password2: null,
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      return checkPasswordAPI.execute({}, data).then(({ data: { item: { redirect } } }) => {
        window.location.href = redirect
      })
    },
  },
}
</script>
<style scoped>
  .title {
    color: #212121;
    font-size: 20px;
    line-height: 26px;

  }

  .subtitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212121;
    padding-top: 8px;
    text-align: center;
  }
  .button-wrapper {
    text-decoration: none;
    height: 40px;
  }
  .button-wrapper > p {
    text-decoration: none;
  }

  .button-wrapper:hover {
    background-color: #5CA300;
  }
</style>
