<template>
<div>
  <router-view/>
  <success-payment/>
</div>
</template>

<script>

import { mapActions } from 'vuex'
import SuccessPayment from '@app/modals/SuccessPayment.vue';

export default {
  name: 'cabinet-client',
  components: { SuccessPayment },
  async mounted() {
    await this.countryAPI()
    await this.projectSelectsRequest()
    this.favoriteListIdRequest()
  },
  methods: {
    ...mapActions('countriesList', [
      'countryAPI',
    ]),
    ...mapActions('projectSelects', [
      'projectSelectsRequest',
    ]),
    ...mapActions('favoriteList', [
      'favoriteListIdRequest',
    ]),
  },
}
</script>
