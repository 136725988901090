<template lang="pug">
include ../Bemto
+b.error-page
  +b.panel.--br-default.--bg-white.error-wrapper
    +b.H1.text.--size-2xl-md.--size-2xxl-xl.--font-serif(
      style='text-align: center'
    ) {{ _('Error 404') }}
    +b.P.text.--font-serif.--align-center.--size-lg-md.--size-2lg-xl {{ _('Unfortunately this page could not be found') }}
    +b.A.ma--t-2xl.--b-lg(
      href='/'
    )
      +b.el-btn.--default.text.--color-white.--transform-upper.--space-lg(
        :style="{width: '240px'}"
      ) {{ _('Home') }}
</template>

<script>
export default {
  name: 'not-found',
}
</script>
