<template lang="pug">
include ../Bemto
+b.auth-modal
  +b.close-modal(
    @click.prevent='$emit("close")'
  )
    +b.APP-ICON(name="icon-cross")
  +b.P.text.--weight-bold.--size-2lg.--size-xl-sm.--color-black.--font-serif {{ _('Telegram subscription title') }}
  +b.P.text.--size-sm.--color-dark-gray.ma--t-2xs.ma--b-2sm {{ _('Telegram subscription body') }}
  telegram-prompt.ma--b-sm(
    :style="{margin: '0 auto'}"
  )
</template>

<script>

export default {
  mounted() {
    sessionStorage.setItem('modalShow', true)
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>
