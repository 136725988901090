<template lang="pug">
include ../../Bemto
router-view
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'cabinet-admin',
  async mounted() {
    await this.countryAPI()
    await this.projectSelectsRequest()
    this.favoriteListIdRequest()
  },
  methods: {
    ...mapActions('countriesList', [
      'countryAPI',
    ]),
    ...mapActions('projectSelects', [
      'projectSelectsRequest',
    ]),
    ...mapActions('favoriteList', [
      'favoriteListIdRequest',
    ]),
  },
}
</script>
