<template lang='pug'>
  include ../Bemto
  div
    +b.filter-bar-top
      +e.selected(v-if="filters?.filters && Object.values(filters?.filters)?.length && isDesktop")
        cell
          search-chips(
            @skip='clear'
            :totalItems='totalItems'
            :all='allFilters'
            :filters='filters.filters'
            @update:filters="changeFilters"
          )

    +b.i-catalog
      +e.sidebar
        +e.search
          +b.search-wrap
            input.search-input(
              v-model='name'
              @input='debounceSearch'
              :placeholder="_('Input talent name')"
            )

        catalog-sidebar(
          :filters="filters"
          @update:filters="changeFilters"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :additionalResource="additionalResource"
          :label='label'
          :totalItems="totalItems"
          :filterName="filterName"
          @get:filters:list="setFiltersList"
          :hasOtherFilter="hasOtherFilter"
        )
      +e.DIV.mobile-chip(v-if="filters?.filters && Object.values(filters?.filters)?.length && !isDesktop")
        +b.P.mobile-chip-text {{ _("Акторів знайдено: ") }}
          +b.SPAN.mobile-chip-text {{ totalItems }}
        +b.DIV.mobile-chip-button-cancel(@click="clear") {{ _("Скинути") }}
      +e.content
        +b.i-preloader.--variant_1(:class="{'is-active': loading}")
          +e.item.--size_md
        row.content-mobile(
          v-if="result && result.items.length"
          :space="'sm'"
          :justify="['center', 'start-md', 'center-lg']"
        )
          cell.content-card(
            cols="6 4-lg"
            :style="{marginBottom: '30px'}"
            v-for="(talent, index) in result.items"
            :key="`${talent.id}_${index}`"
          )
            talent-card.talent-page(
              :talent='talent'
              :projects='projects'
            )
        +b.i-panel(v-else-if="!loading")
          +b.DIV.not-found-wrapper
            +b.IMG(
              width="80"
              height="80"
              src="/static/img/person_search.png"
            )
            +b.P.not-found-text {{ _('Акторів не знайдено') }}
        +b.DIV.pagination-more-wrapper(v-if="this.result?.pagination?.limit < this.result?.pagination?.total")
          +b.pagination-more-content(@click="handleLimit")
            app-icon(name="icon-refresh-list" size="xl")
            +b.P.pagination-more-text {{ _('Показати ще') }}
        ui-limit-pagination.talent-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
        +b.DIV.bottom_wrapper
</template>

<script>
import { debounce } from 'lodash'
import SortingWidget from '@app/Filters/Sorting'
import SearchChips from '@app/List/Chips'
import {
  projectsListResource,
} from '@requests/services/catalog'
import UiMixin from '@app/Filters/mixins/UiMixin'
import CatalogSidebar from './Sidebar'

export default {
  mixins: [UiMixin],
  components: {
    CatalogSidebar,
    SortingWidget,
    SearchChips,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
    'hasOtherFilter',
  ],
  data() {
    console.log('filters', this.filters)
    return {
      name: '',
      allFilters: {},
      debounceSearch: null,
      projects: [],
      isDesktop: false,
      isClient: 'client' === window.ROLE,
    }
  },
  created() {
    this.receive(this.parameters)
  },

  mounted() {
    const timeout = 300

    this.debounceSearch = debounce(this.search, timeout)
    console.log('isClient', this.isClient)
    if (this.isAdmin || this.isClient) {
      this.getProjects()
    }
    this.checkWidth()
    window.addEventListener('resize', () => {
      this.checkWidth()
    })
  },

  methods: {
    setFiltersList(filters) {
      this.allFilters = filters
    },
    checkWidth() {
      this.isDesktop = window.innerWidth >= this.windWidth;
    },
    getData() {
      this.receive(this.parameters)
    },
    clear() {
      this.receive({})
      this.changeFilters({})
      this.name = ''
    },
    search() {
      this.changeFilters({ ...this.parameters, filters: { ...(this.parameters?.filters || {}), name: this.name } })
    },
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
    handleLimit() {
      if (this.loading) {
        return;
      }
      const newLimit = 12;
      this.changePagination({ limit: this.result?.pagination?.limit + newLimit, offset: 0 });

      // Filter items based on the new limit
      this.result.items = this.result.items.slice(0, newLimit);
    },
    getProjects() {
      this.isLoad = true
      const params = {
        filters: {
          status: 0,
        },
      }
      projectsListResource.execute(params).then(res => {
        const { data } = res
        this.projects = data.items
      }).finally(() => {
        this.isLoad = false
      })
    },
  },
}

</script>
<style scoped>
.input-margin {
  margin-bottom: 24px
}

.pagination-more-wrapper {
  display: flex;
  justify-content: center;
}
.pagination-more-content {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}

.pagination-more-text {
  color: #7069A9;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
}

.bottom_wrapper {
  padding-bottom: 107px;
}
</style>

<style scoped>
@media (max-width: 120px) {
  .i-catalog__sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
}

.content-mobile {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}

.i-catalog__mobile-chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  padding: 15px 0;
  margin-bottom: 16px;
}

.mobile-chip-text {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.mobile-chip-text > span {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.mobile-chip-button-cancel {
  border: 2px solid #7069A9;
  color: #7069A9;
  border-radius: 50px;
  width: 92px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
}
.content-card {
  max-width: 348px;
}

@media (max-width: 800px) {
  .content-card {
    max-width: 274px;
  }
}

@media (max-width: 600px) {
  .content-card {
    width: 100%;
    max-width: 348px;
  }
}
.g-cell {
  padding: 0 !important;
}

.not-found-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding-top: 24px
}
.not-found-text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}
</style>
