import * as rules from 'vee-validate/dist/rules'
import {
  extend,
} from 'vee-validate'

// loop over all rules
/* eslint-disable no-restricted-syntax, guard-for-in */
for (const rule in rules) {
  // add the rule
  extend(rule, rules[rule])
}
