import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VModal from 'vue-js-modal'
import DatePicker from 'vue2-datepicker'
import Range from 'vue-slider-component'
import Notifications from 'vue-notification'
import VueSlickCarousel from 'vue-slick-carousel'
import VueMaterialTabs from 'vue-material-tabs'
import VTooltip from 'v-tooltip'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import 'vue-slider-component/theme/default.css'

import Tag from '@aspectus/vue-tag'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import VueDermis from '@aspectus/vue-dermis';
import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader';
import '@utils/events'
import 'vue-animate-transitions/dist/vue-animate-transitions.min.css'
import ResourceComponents from '@requests/resources'
import VueAccordion from '@ztrehagem/vue-accordion'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './styles/project/components/toast.sass'

require(`vue2-datepicker/locale/${window.language}`);

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(VueMaterialTabs)
  Vue.use(VTooltip)
  Vue.use(Notifications)
  Vue.use(DatePicker)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
    },
  })
  Vue.use(VueDropzoneUploader);
  Vue.use(Tag)
  Vue.use(VueGrid)
  Vue.use(VueDermis)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(ResourceComponents)
  Vue.component('vue-accordion', VueAccordion)
  Vue.component('range-slider', Range)
  Vue.component('vue-slick-carousel', VueSlickCarousel)
  Vue.use(VueToast)
}

export default { install }
