<template>
  <div class="login-reference-text">
    Вже маєте акаунт? <a href="/auth/login" class="login-reference-text_ref">Увійти</a>
  </div>
</template>
<script>
export default {
  name: 'login-reference-text',
}
</script>
<style scoped>
.login-reference-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;

  margin-top: 4px;
}

.login-reference-text_ref {
  color: #0171c2;
}
</style>
