import { escapeRegex } from './escapeRegex';

export const BRACKETS = ['{{', '}}'];
export const TRANSLATION_BRACKETS = ['<[', ']>'];

export function format(string, kwargs, brackets = BRACKETS) {
  let str = String(string);
  const start = escapeRegex(brackets[0]);
  const end = escapeRegex(brackets[1]);

  Object.keys(kwargs).forEach(key => {
    str = str.replace(new RegExp(`${start}${key}${end}`, 'img'), kwargs[key]);
  });

  return str;
}

export function formatTranslation(string, kwargs) {
  return format(string, kwargs, TRANSLATION_BRACKETS);
}
