import VueRouter from 'vue-router'
import { ROUTES } from './paths'

const router = new VueRouter(window.IS_CABINET ? {
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: ROUTES,
} : {
  mode: 'history',
  routes: ROUTES,
})

export default router
