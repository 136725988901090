export default {
  bind(el, binding) {
    // eslint-disable-next-line no-underscore-dangle
    el.__clickOutsideHandler__ = event => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    // eslint-disable-next-line no-underscore-dangle
    document.addEventListener('click', el.__clickOutsideHandler__);
  },
  unbind(el) {
    // eslint-disable-next-line no-underscore-dangle
    document.removeEventListener('click', el.__clickOutsideHandler__);
    // eslint-disable-next-line no-underscore-dangle
    el.__clickOutsideHandler__ = null;
  },
};
