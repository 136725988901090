<template lang="pug">
include ../../Bemto
+b.rectangle-checkbox-block
  slot
  +e.error {{ errorText }}
  row(
    :space='rowSpace'
    v-bind="rowAttributes"
  )
    cell(
      v-for="(item, index) in checkboxData"
      :key='index'
      :cols="colsArray"
    )
      +b.el-checkbox
        +e.INPUT.hidden-checkbox(
          type='checkbox'
          v-model='value'
          :name='`${name}`'
          :value='item.value'
          :id='`${name}_${item.value}_${index}`'
          @change="change"
        )
        +e.LABEL.fake-checkbox(
          :for='`${name}_${item.value}_${index}`'
          )
          +b.P.text {{ item.displayName }}

        +b.tooltip-block.--skills(
          @click.stop.self.prevent
          v-tooltip="{ content: item.tooltip, placement: 'top-center', triggers: ['hover', 'focus', 'touch'] }"
          v-if="item.tooltip && item.tooltip.length"
        )
          +e.icon-wrap
            +e.icon(
              @click.stop.self.prevent
            )
              +b.P.text.--color-light-blue.--weight-bold i
</template>

<script>
export default {
  name: 'checkbox-rectangle',
  props: {
    rowSpace: {
      type: Array,
      default: () => (['xl']),
    },
    setValue: {},
    errorText: String,
    name: String,
    checkboxData: Array,
    colsArray: Array,
    rowAttributes: {},
  },
  data() {
    return {
      value: [],
    }
  },
  watch: {
    setValue(val) {
      this.value = val || []
    },
  },
  mounted() {
    this.value = this.setValue || []
  },
  methods: {
    change() {
      this.$emit('checkbox-data', this.value)
    },
  },
}
</script>
