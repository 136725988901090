<template lang="pug">
  include ../Bemto
  +b.d-control-input__label--static
    label(:for='id') {{ inputLabel }}
    span(
      v-if='isRequired'
      v-bem.is-required=''
    ) *
    span &nbsp;
    span.static-hint(
      v-if="hint"
      v-html="hint"
    )
    //- +b.g-cell(v-if="target")
      +b.A.ds-link.--variant_1.--flex.is-not-underline(
        target="_blank"
        :href="target"
      )
        +b.I.icon-target
</template>

<script>
export default {
  name: 'StaticLabel',
  block: 'd-control-input',
  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    target: {
      type: String,
    },
  },
}
</script>
