<template lang="pug">
include ../../Bemto
+b.tooltip-item(
  @click.stop
  v-tooltip="{ content: tooltipText, trigger: 'click touch', }"
)
  +e.P.item.text.--color-light-blue.--weight-bold i
</template>

<script>
export default {
  name: 'label-tooltip',
  props: {
    tooltipText: {
      type: String,
    },
  },
}
</script>
