<template lang="pug">
include ../Bemto
+b.talent-main
  talants-list-view
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'talents-page',
  props: {
    hasFavorites: {
      type: Boolean,
    },
    isAdmin: Boolean,
  },
  provide() {
    return {
      isAdmin: this.isAdmin,
    }
  },
  mounted() {
    // this.favoriteListRequest()
    if (this.hasFavorites) {
      this.favoriteListIdRequest()
    }
    if (this.isAdmin) {
      this.projectListRequest()
    }
    this.currentUserAPI()
    this.characteristicsRequest()
  },
  methods: {
    ...mapActions('currentUser', ['currentUserAPI']),
    ...mapActions('favoriteList', ['favoriteListIdRequest']),
    // ...mapActions('favoriteList', ['favoriteListRequest']),
    ...mapActions('unionProjectsList', ['projectListRequest']),
    ...mapActions('talentCharacteristics', ['characteristicsRequest']),
  },
}
</script>
