import { renderSlim } from '@aspectus/vue-utils';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';

const createChecker = (callback, promise) => value => callback(value, promise);

export default {
  name: 'resource-loader-controller',
  mixins: [LoadingStateMixin],

  props: {
    resource: {},
  },

  data() {
    return {
      result: null,
    };
  },

  methods: {
    applyResult(result, promise) {
      if (this.$options.promise !== promise) {
        return;
      }
      if (result.code) {
        this.result = result.data
        this.$emit('result', result);
        return
      }
      this.result = result;
      this.$emit('result', result);
    },

    receive(parameters) {
      if (this.$options.promise && this.$options.promise.cancel) {
        this.$options.promise.cancel();
      }

      if (!this.resource) return false
      const promise = this.resource.execute(parameters);
      this.$options.promise = promise;

      return this.$load(promise.then(createChecker(this.applyResult, promise)));
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      loading: this.loading,
      result: this.result,
      receive: this.receive,
    }), h, 'tag');
  },
};
