import {
  baseResource,
} from '@requests/resources/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixAPI } from '@requests/resources/api'

const PRODUCTS_FILTERS_URL_PREFIX = prefixAPI('filters')
const PRODUCTS_FILTERS_URL_POSTFIX = ''

const PRODUCTS_LIST_URL_PREFIX = prefixAPI('talents/list/')
const PRODUCTS_LIST_URL_POSTFIX = ''

const PROJECTS_LIST_URL_PREFIX = prefixAPI('projects/list/')
const PROJECTS_LIST_URL_POSTFIX = 'ordering=-created_at'

const ADMIN_TALENTS_LIST_URL_PREFIX = prefixAPI('admin/talents/list/')
const ADMIN_TALENTS_LIST_URL_POSTFIX = ''

const FAVORITES_LIST_URL_PREFIX = prefixAPI('favorites/list/')
const FAVORITES_LIST_URL_POSTFIX = ''

const TALENTS_PROJECT_LIST_URL_PREFIX = prefixAPI('/talents/talents/project/list')
const TALENTS_PROJECT_LIST_URL_POSTFIX = ''

const PAID_TALENTS_URL_PREFIX = prefixAPI('/accounts/paid-talents')
const PAID_TALENTS_URL_POSTFIX = ''

const order = {
  path: [
    'prefix',
    'label',
  ],
  query: [
    'query',
  ],
}

export const productsFilterResource = friendlyUrlGenerator(
  [PRODUCTS_FILTERS_URL_PREFIX, PRODUCTS_FILTERS_URL_POSTFIX], baseResource, order
)

export const productsListResource = friendlyUrlGenerator(
  [PRODUCTS_LIST_URL_PREFIX, PRODUCTS_LIST_URL_POSTFIX], baseResource, order
)

export const projectsListResource = friendlyUrlGenerator(
  [PROJECTS_LIST_URL_PREFIX, PROJECTS_LIST_URL_POSTFIX], baseResource, order
)

export const adminTalentsListResource = friendlyUrlGenerator(
  [ADMIN_TALENTS_LIST_URL_PREFIX, ADMIN_TALENTS_LIST_URL_POSTFIX], baseResource, order
)

export const favoritesListResource = friendlyUrlGenerator(
  [FAVORITES_LIST_URL_PREFIX, FAVORITES_LIST_URL_POSTFIX], baseResource, order
)

export const talentsProjectListResource = friendlyUrlGenerator(
  [TALENTS_PROJECT_LIST_URL_PREFIX, TALENTS_PROJECT_LIST_URL_POSTFIX], baseResource, order
)

export const paidTalentsResource = friendlyUrlGenerator(
  [PAID_TALENTS_URL_PREFIX, PAID_TALENTS_URL_POSTFIX], baseResource, order
)
