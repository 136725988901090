import UiClickOutside from './UiClickOutside'
import UiDropdown from './UiDropdown'
import UiAccordionTemplate from './UiAccordionTemplate'
import MenuTrigger from './MenuTrigger'
import LightBox from './LightBox'
import PhotoPreview from './PhotoPreview'
import LabelTooltip from './LabelTooltip'
import TgPrompt from './TgPrompt'
import Icons from './icons';
import LoadingWrapper from './LoadingWrapper'

const Icon = () => import('./Icon.vue')
const UiVideoView = () => import('./UiVideoView.vue')
const UiSwitch = () => import('./UiSwitch')

export default function install(Vue) {
  Vue.use(Icons)

  Vue.component('app-icon', Icon)
  Vue.component('ui-video-view', UiVideoView)
  Vue.component('LoadingWrapper', LoadingWrapper)
  Vue.component('UiSwitch', UiSwitch)
  Vue.component(UiClickOutside.name, UiClickOutside)
  Vue.component(UiDropdown.name, UiDropdown)
  Vue.component(UiAccordionTemplate.name, UiAccordionTemplate)
  Vue.component(LightBox.name, LightBox)
  Vue.component(PhotoPreview.name, PhotoPreview)
  Vue.component(MenuTrigger.name, MenuTrigger)
  Vue.component(LabelTooltip.name, LabelTooltip)
  Vue.component(TgPrompt.name, TgPrompt)
}
