<template lang="pug">

include /mixins.pug

vue-dropzone-uploader(
  :resource="uploadResource",
  @upload="uploadHandler",
  v-bind="$attrs",
  :multiple="multiple"
  :config="config",
  :autoload="autoload"
  @drop="drop",
  @reject="$emit('reject')"
  ref="uploader"
)
  //- template(#cover)
  //-   +e.cover
  //-     ds-caption(size="5") {{ _('Можно отпускать.') }}

  template(#default="uploader")
    slot(
      :open="open", :remove="remove", :uploader="uploader", :value="value",
      :clear="clear"
    )
      +b.gallery-block
        +b.gallery
          //- +b.uploaded-wrapper(
          //-   v-for="(item, index) in downloadedPhotos"
          //-   :key='`old_${index}`'
          //- )
            light-box(
              :source='downloadPhotos'
              :style="{width: '100%', height: '100%'}"
            )
              template(v-slot:default='{ show }')
                +b-context('uploaded-wrapper')
                  +e.remove(
                    @click='remove(index, item)'
                  )
                    +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
                  +e.photo.IMG(
                    :src='item.imageLinks.fullSize'
                    @click='show(index)'
                  )
        light-box(
          v-if="!multiple && value"
          :source='value ? [value && value.file] : []'
          v-slot="{ show }"
        )
          +b.gallery
            +b.uploaded-wrapper.if(
            )
              +e.remove(
                @click='remove(value)'
              )
                +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
              +e.photo.IMG(
                :src='value && value.file'
                @click='show(0)'
              )
        light-box(
          v-else
          :source='(value || []).map(el => el.file)'
          v-slot="{ show }"
        )
          +b.gallery
            +b.uploaded-wrapper.else(
              v-for="(item, index) in value"
              :key='`new_${item.id}`'
            )
              +e.remove(
                @click='remove(item)'
              )
                +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
              +e.photo.IMG(
                :src='item.file'
                @click='show(index)'
              )
        light-box(
          :source='(uploader.accepted || []).map(el => el.file.url)'
          v-slot="{ show }"
        )
          +b.gallery
            +b.uploaded-wrapper.accepted(
              v-for="(file, index) in uploader.accepted"
              :key='`new_${index}`'
            )
              +e.remove(
                @click='remove(file)'
              )
                +b.APP-ICON.text.--color-white.--weight-bold.--size-xs(name="icon-cross")
              +e.photo.IMG(
                :src='file.file.url'
                @click='show(index)'
              )
              .progress-wrap
                .progress-wrapper
                  .progress-fill(
                    :style="`width: ${file.progress.relative * 100}%;`"
                  )
          +b.gallery__fake-file-input(
            v-if="canUploadMore"
            :class="{'is-show': multiple || !multiple && !(value && value.file || uploader.accepted.length) }"
            @click="open",

          )
            +b.APP-ICON.text(name="icon-plus")

</template>

<script>
import accepts from 'attr-accept';
import { uploadImageAPI, deleteUploadedImageAPI } from '@requests/services/services'

export default {
  name: 'files-uploader',
  uploadResource: uploadImageAPI,
  props: {
    btnText: {
      type: String,
      default: undefined,
    },
    multiple: {
      default: true,
    },
    canUploadMore: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Array, Object],
    },
    uploadResource: {
      default: () => uploadImageAPI,
    },
    accept: String,
    autoload: {
      type: Boolean,
      default: true,
    },
    handler: {
      type: Function,
    },
    file: {
      type: Object,
    },
    maxSizeFile: {
      type: Number,
      default: 5242880,
    },
  },
  data() {
    return {
      maxSizeUpload: this.maxSizeFile,
      maxLengthName: 120,
      localValue: [],
    };
  },
  computed: {
    config() {
      return { acceptor: this.acceptor };
    },
  },
  mounted() {
    if (this.accept && this.$refs?.uploader?.$refs?.dropzone?.$refs?.input) {
      this.$refs.uploader.$refs.dropzone.$refs.input.setAttribute('accept', this.accept);
    }
  },
  methods: {
    uploadHandler({ result: { data: { item: result } }, descriptor }) {
      this.$refs.uploader.remove(descriptor);
      if (!this.multiple) {
        this.input(result);
      } else {
        this.localValue.push(result)
        this.input(this.localValue);
      }
    },
    input(data) {
      this.$emit('input', data);
    },
    open() {
      this.$refs.uploader.open();
    },
    drop(e) {
      const { accepted, rejected } = e;
      const files = accepted.map(file => this.$refs.uploader.makeFile(file, 'ready'));
      const failed = rejected.map(file => this.$refs.uploader.makeFile(file, 'failure'));
      if (this.multiple) {
        this.$refs.uploader.rejected = this.$refs.uploader.rejected.concat(failed);
        this.$refs.uploader.accepted = this.$refs.uploader.accepted.concat(files);
      } else {
        this.$refs.uploader.rejected = failed;
        this.$refs.uploader.accepted = files;
      }

      failed.forEach(this.$refs.uploader.reject);
      if (this.$refs.uploader.autoload) {
        files.forEach(this.$refs.uploader.upload);
      }
    },
    clear() {
      this.input(null);
    },
    async remove(file) {
      const { id } = file;

      if (this.multiple) {
        const index = this.value.findIndex(x => x.id === id);
        if (-1 !== index) {
          this.value.splice(
            index,
            1
          );
        }
        deleteUploadedImageAPI.execute({ id })
      } else {
        this.input(null);
        this.$refs.uploader.accepted = [];
      }
    },
    acceptor(file) {
      const BYTE = 1024;
      if (this.maxLengthName < file.name.length) {
        this.$toast.error(this._('Make sure the file name is less than 100 characters'));
        return false;
      }
      if (file.size / BYTE > this.maxSizeUpload) {
        this.$toast.error(
          // eslint-disable-next-line prefer-template
          this._('File size must be less than') + ' ' + (this.maxSizeUpload / BYTE) + ' ' + this._('MB')
        );
        return false;
      }
      return this.accept ? accepts(file, this.accept) : true;
    },
  },
};
</script>
