<template lang="pug">
include ../Bemto
+b.error-page
  +b.panel.--br-default.--bg-white.error-wrapper
    +b.H1.text.--size-2xl-md.--size-2xxl-xl.--font-serif(
      style='text-align: center'
    ) {{ _('Error 403') }}
    +b.P.text.--font-serif.--align-center.--size-2lg {{ _('Unfortunately this page is not available') }}
    +b.A.ma--t-2xl.--b-lg(
      href='/'
    )
      +b.BUTTON.el-btn.--default.text.--color-white.--transform-upper.--space-lg(
        :style="{width: '240px'}"
      ) {{ _('Home') }}
</template>

<script>
export default {
  name: 'no-access',
}
</script>
