<template lang="pug">
include ../../Bemto
ui-click-outside(:do='hideBar')
  div
    slot
    +b.project-edit(v-if="isVisible")
      +e.edit-wrap.ma--b-2xs(
        v-if="showToArchive"
        @click="$emit('project-to-archive')"
      )
        +e.APP-ICON.edit-icon(
          name="icon-eye-on"
        )
        +b.P.text.--size-sm.--pointer-blue {{ _('В архів') }}
      +e.edit-wrap.ma--b-2xs(
        v-if='isEdit'
        @click="$emit('edit-project')"
      )
        +e.APP-ICON.edit-icon.icon(name="icon-pen")
        +b.P.text.--size-sm.--pointer-blue {{ _('Редактировать') }}
      +e.edit-wrap(
        @click="$emit('remove-project')"
      )
        +e.APP-ICON.edit-icon.icon(name="icon-trash")
        +b.P.text.--size-sm.--pointer-blue {{ _('Удалить') }}
</template>

<script>
export default {
  name: 'project-edit',
  props: ['isVisible', 'isEdit', 'showToArchive'],
  methods: {
    hideBar() {
      this.$emit('close-bar')
    },
  },
}
</script>
