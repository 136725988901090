/* eslint-disable */
const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors, initKey, accumulator = {}) {
  return Object.keys(errors).reduce((acc, x) => {
    const key = initKey ? `${initKey}:${x}` : x;
    if (Array.isArray(errors[x])) {
      if (Object.prototype.hasOwnProperty.call(errors[x][0], 'message')) {
        acc[key] = errors[x].map(messageGetter);
        return acc;
      }
      for (let i = 0, n = errors[x].length; i < n; i++) {
        defaultValidatorErrorsParser(errors[x][i], `${key}:${i}`, acc);
      }
      return acc;
    }
    if ('object' !== typeof errors[x]) return acc;
    return defaultValidatorErrorsParser(errors[x], key, acc);
  }, accumulator);
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
      requiredScrollToError: false,
    }
  },
  mounted() {

  },
  methods: {

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
      if (this.requiredScrollToError) {
        this.scrollToFirstError(parsed);
      }
    },
    scrollToFirstError(errors) {
      let first;
      Object.entries(errors).some(([key, value]) => {
        if (value && value.length) first = key;
        return value && value.length;
      });
      // eslint-disable-next-line no-unused-expressions
      if (
        first &&
        this.$refs.validator.refs[first] &&
        this.$refs.validator.refs[first].$el &&
        this.$refs.validator.refs[first].$el.scrollIntoView
      ) {
        this.$refs.validator.refs[first].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }

        if (!this.updateValidator) {
          return e
        }

        return e.json().then(body => {
          const errors = {}
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.updateValidator(errors, body.errors)
          return e
        })
      })
    },
    submit(valid, data) {
      if (!valid) {
        return Promise.reject()
      }
      this.isLoad = true
      return this.catchFormErrors(this.send(data))
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
