import RegistrationClient from './RegistrationClient';
import SignUp from './SignUp'
import AgreementCheckbox from './AgreementCheckbox.vue';
import LoginReferenceText from './LoginReferenceText.vue';
import ClientRegistrationFinish from './ClientRegistrationFinish.vue';

export default function install(Vue) {
  Vue.component(SignUp.name, SignUp);
  Vue.component(RegistrationClient.name, RegistrationClient);
  Vue.component(AgreementCheckbox.name, AgreementCheckbox)
  Vue.component(LoginReferenceText.name, LoginReferenceText)
  Vue.component(ClientRegistrationFinish.name, ClientRegistrationFinish)
}
