<template lang="pug">
include ../Bemto
.tab-row: LoadingWrapper(:loading="isLoad"): row(
  :space="['none']"
)
  cell.tab-title-cell(
    :cols="['12', '4-md', 'auto-xl']"
    ref='topOfContent'
  )
    +b.P.text.--color-gray.--weight-default.--size-sm {{ _('Hello') + ' ' + name + '!' }}
    +b.H1.greetings {{ _('A couple more facts about you') }}
    +b.mobile-tab
      +e.circle(
        v-for="(tab, index) in tabs"
        :key='index'
        :class="[index < activeTab ? 'mobile-tab__circle--watched' : index === activeTab ? 'mobile-tab__circle--active' : '']"
      )
        +b.P.text.--font-sans.--weight-semi-bold(
          v-if='index > activeTab'
        ) {{ index+1 }}

        +b.APP-ICON.text--color-green(
          name="icon-check-mark"
          v-else-if='index < activeTab'
        )
        +b.aim(
          v-if='index === activeTab'
        )
          +b.pulse
    +b.tab(
      v-for="(tab, index) in tabs"
      :key='index'
    )
      +b.H2.tab-title.text.--font-sans(
        :data-id='index'
        :class="{ active: index === activeTab, watched: index < activeTab }"
      ) {{ tab.title }}
        +b.SPAN.index(
          v-if='index < activeTab'
        )
          +b.ma--r-2xxs
            +b.APP-ICON.text--color-green(
              name="icon-check-mark"
            )
        +b.aim(
          v-if='index === activeTab'
        )
          +b.pulse
        +b.SPAN.index(
          v-if='index > activeTab'
        ) {{ index + 1 }}
  cell.tab-content-cell(
    :cols="['12', '8-md', 'auto-xl']"
  )
    +b.form-block(
      v-for="(content, index) in tabs"
      v-show='content.visible'
      :key='content.title'
    )
      +b.P.content-title.text.--color-green.--font-sans.--size-1sm.--weight-semi-bold.ma--b-sm {{ content.title }}
      +b.content(
        v-if='index === 1'
      )
        div
          +b.content-wrapper
            radio-bar.gender-radio(
              :name="'gender'"
              :radio-data='filters.genders'
              @radio-value="setFormData($event, 'gender')"
            )
              div(
                :class="{ 'ma--b-2sm': !(errors && errors.gender) }"
              )
                +b.P.text.--color-black.--size-sm.--font-sans.--weight-bold {{ _('Choose gender') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.gender"
                ) {{ errors.gender[0].message }}
            +b.date-wrapp.ma--t-sm.ma--t-none-sm
              +b.P.text.--size-sm.--weight-bold.--font-sans.--color-black {{ _('Birthday') + '*' }}
              +b.control-descriptor--normalize
                +e.element
                  date-picker.datepicker.pa--t-xs(
                    v-model='form.birthday'
                    :editable='false'
                    :default-value="new Date(new Date().setFullYear((new Date().getFullYear() - 18)))"
                    :format="'DD MMMM YYYY'"
                    :value-type="'DD.MM.YYYY'"
                    type='date'
                    :disabled-date="disableAfterDate({ date: new Date(new Date().setFullYear((new Date().getFullYear() - 18))) })",
                  )
                    template(#icon-calendar)
                      +b.APP-ICON.text--color-green(
                        name="icon-calendar"
                      )
                +e.errors(
                  v-if='errors && errors.birthday && !form.birthday'
                ) {{ errors.birthday[0].message }}
          checkbox-rectangle.skill-registration.ma--t-lg.ma--b-lg(
            :name="'skills'"
            :checkbox-data="filters.skills"
            :colsArray="['12','6-sm', '4-md']"
            :setValue="form.skills || []"
            @checkbox-data="setFormData($event, 'skills')"
          )
            .ma--b-xs
              +b.P.text.--size-sm.--font-sans.--color-black.--weight-bold {{ _("I'm...") + '*' }}
              +b.P.error-static(
                v-if="errors && errors.skills"
              ) {{ errors.skills[0].message }}
          radio-rectangle.skill-registration.ma--t-lg.ma--b-lg(
            :row-space="['md']"
            :name="'professionalPhotoExperience'"
            :radio-data="professionalPhotoExperience"
            :colsArray="['12','6-sm', '4-md']"
            :setValue="form.professionalPhotoExperience"
            @radio-value="setFormData($event, 'professionalPhotoExperience')"
          )
            .ma--b-xs
              +b.P.text.--size-sm.--font-sans.--color-black.--weight-bold {{ _("Досвід професійних комерційних фотозйомок") + '*' }}
              +b.P.error-static(
                v-if="errors && errors.professionalPhotoExperience"
              ) {{ errors.professionalPhotoExperience[0].message }}
          radio-rectangle.skill-registration.ma--t-lg.ma--b-lg(
            :row-space="['md']"
            :name="'professionalVideoExperience'"
            :radio-data="professionalVideoExperience"
            :colsArray="['12','6-sm', '4-md']"
            :setValue="form.professionalVideoExperience"
            @radio-value="setFormData($event, 'professionalVideoExperience')"
          )
            .ma--b-xs
              +b.P.text.--size-sm.--font-sans.--color-black.--weight-bold {{ _("Досвід професійних комерційних відеозйомок") + '*' }}
              +b.P.error-static(
                v-if="errors && errors.professionalVideoExperience"
              ) {{ errors.professionalVideoExperience[0].message }}
        +b.nav-registration
          +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
            :style="{opacity: '0'}"
          ) {{ _('Back') }}
          +e.P.next.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
            @click='checkGeneral(index + 1)'
          ) {{ _('Forward') }}
      +b.content(
        v-if='index === 2'
      )
        +b.visual-data-content(
          v-show='substep'
        )
          div
            radio-rectangle(
              :name="'eyesColors'"
              :colsArray="['6','3-sm']"
              :radio-data='filters.eyesColors'
              @radio-value="setFormData($event, 'eyesColor')"
              :label-size="'md'"
            )
              div(
                :class="[ (errors && errors.eyesColor) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Eyes color') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.eyesColor"
                ) {{ errors && errors.eyesColor[0].message }}
            radio-rectangle(
              :name="'hairType'"
              :colsArray="['6','3-sm']"
              :radio-data='filters.hairTypes'
              @radio-value="setFormData($event, 'hairType')"
              :label-size="'md'"
            )
              div(
                :class="[ (errors && errors.hairType) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-sans.--size-sm.--color-black.--weight-bold {{ _('Hairs') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.hairType"
                ) {{ errors && errors.hairType[0].message }}
            radio-rectangle(
              :name="'hairColor'"
              :colsArray="['6','3-sm']"
              :radio-data='filters.hairColors'
              :setValue="form.hairColor || 0"
              @radio-value="setFormData($event, 'hairColor')"
              :label-size="'md'"
            )
              div(
                :class="[ (errors && errors.hairColor) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-sans.--size-sm.--color-black.--weight-bold {{ _('Hairs color') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.hairColor"
                ) {{ errors && errors.hairColor[0].message }}
            registration-range(
              :classProp="'register-range'"
              :min='0'
              :max='150'
              :range-label="_('kg')"
              :setValue="form.weight || 0"
              @range-data="setFormData($event, 'weight')"
            )
              div(
                :class="[ (errors && errors.weight) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--size-sm.--weight-bold.--color-black.--font-sans {{ _('Weight') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.weight"
                ) {{ errors && errors.weight[0].message }}
          +b.nav-registration
            +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
              @click='show(index - 1)'
            ) {{ _('Back') }}
            +e.P.next.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
              @click='checkVisualData'
            ) {{ _('Forward') }}
        +b.visual-data-content(
          v-show='!substep'
        )
          div
            radio-rectangle(
              :name="'bodyType'"
              :colsArray="['12', '4-sm']"
              :radio-data='filters.bodyTypes'
              :setValue="form.bodyType || []"
              @radio-value="setFormData($event, 'bodyType')"
              :label-size="'lg'"
            )
              div(
                :class="[ (errors && errors.bodyType) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Body type') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.bodyType"
                ) {{ errors && errors.bodyType[0].message }}
            radio-rectangle(
              :name="'skinColor'"
              :colsArray="['12', '4-sm']"
              :radio-data='filters.skinColors'
              :label-size="'lg'"
              :setValue="form.skinColor || []"
              @radio-value="setFormData($event, 'skinColor')"
            )
              div(
                :class="[ (errors && errors.skinColor) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Skin color') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.skinColor"
                ) {{ errors && errors.skinColor[0].message }}
            checkbox-rectangle(
              :name="'specialSigns'"
              :checkbox-data="filters.specials"
              :colsArray="['12', '6-sm', '4-xl']"
              :setValue="form.specials || []"
              @checkbox-data="setFormData($event, 'specials')"
            )
              div(
                :class="[ (errors && errors.special) ? 'ma--b-xs' : 'ma--b-2xs']"
              )
                +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Special signs') }}
                +b.P.error-static(
                  v-if="errors && errors.special"
                ) {{ errors && errors.special[0].message }}
          +b.nav-registration
            +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
              @click='back()'
            ) {{ _('Back') }}
            +e.P.next.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
              @click='checkBodyData(index + 1)'
            ) {{ _('Forward') }}
      +b.content(
        v-if='index === 3'
      )
        div
          radio-rectangle.registration-clothing(
            :row-space="['md']"
             :name="'clothingSizeTop'"
             :radio-data='filters.clothingSizes'
             :colsArray="['4','3-sm','narrow-xl']"
             @radio-value="setFormData($event, 'clothingSizeTop')"
          )
            div(
              :class="[ (errors && errors.clothingSizeTop) ? 'ma--b-xs' : 'ma--b-2xs']"
            )
              +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Clothing size - top') + '*' }}
              +b.P.error-static(
                v-if="errors && errors.clothingSizeTop"
              ) {{ errors.clothingSizeTop[0].message }}
          radio-rectangle.registration-clothing(
            :row-space="['md']"
             :name="'clothingSizeBottom'"
             :radio-data='filters.clothingSizes'
             :colsArray="['4','3-sm','narrow-xl']"
             @radio-value="setFormData($event, 'clothingSizeBottom')"
          )
            div(
              :class="[ (errors && errors.clothingSizeBottom) ? 'ma--b-xs' : 'ma--b-2xs']"
            )
              +b.P.text.--font-gotham.--size-sm.--color-black.--weight-bold {{ _('Clothing size - bottom') + '*' }}
              +b.P.error-static(
                v-if="errors && errors.clothingSizeBottom"
              ) {{ errors.clothingSizeBottom[0].message }}
          radio-rectangle.shoe-size(
            :name="'shoeSize'"
            :radio-data='filters.shoeSizes'
            :colsArray="['4','3-sm','2-xl']"
            @radio-value="setFormData($event, 'shoeSize')"
          )
            div(
              :class="[ (errors && errors.shoeSize) ? 'ma--b-xs' : 'ma--b-2xs']"
            )
              +b.P.text.--font-gotham.--color-black.--size-sm.--weight-bold {{ _('Shoe size') + '*' }}
              +b.P.error-static(
                v-if="errors && errors.shoeSize"
              ) {{ errors.shoeSize[0].message }}
          registration-range(
            :classProp="'register-range'"
            :range-label="_('sm')"
            :min='0'
            :max='250'
            @range-data="setFormData($event, 'height')"
          )
            div(
              :class="[ (errors && errors.shoeSize) ? 'ma--b-xs' : 'ma--b-2xs']"
            )
              +b.P.text.--size-sm.--color-black.--weight-bold.--font-gotham {{ _('Height') + '*' }}
              +b.P.error-static(
                v-if="errors && errors.height"
              ) {{ errors.height[0].message }}
        +b.nav-registration
          +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
            @click='show(index - 1)'
          ) {{ _('Back') }}
          +e.P.next.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold(
            @click='checkClothData(index + 1)'
          ) {{ _('Forward') }}
      //- +b.content(
      //-   v-if='index === 4'
      //- )
        div
          photo-uploader(
            :max-files='4'
            :file-counter='true'
            :name="'gallery'"
            :field-label="_('Gallery')"
            :error-text="errors && errors.photos ? errors.photos[0].message : ''"
            @photo-data="setFormData($event, 'photos')"
            :setValue=[]
          )
          +b.P.photo-prompt.text.--size-xs.--color-light-blue {{ _('More than 4 photos can be uploaded in your account after registration') }}
          +b.data-block
            +b.pa--t-sm.pa--b-xs
              +b.P.text.--size-sm.--font-gotham.--weight-bold {{ _('Video') }}
              +b.P.text.--size-xs.--color-light-blue {{ _('Insert a link to YouTube videos') }}
            row(
              :align="'center'"
            )
              cell(
                :cols="['12', '4-lg']"
              )
                +b.P.text.--size-sm {{ _('Презентация о себе') }}
              cell
                d-control-input.thin-input.video-input(
                  v-model='form.videoPresentation'
                  rules='url'
                )
            row.ma--t-sm(
              :align="'center'"
            )
              cell(
                :cols="['12', '4-lg']"
              )
                +b.P.text.--size-sm {{ _('Видео со способностями') }}
              cell
                d-control-input.thin-input.video-input(
                  v-model='form.abilityVideo'
                  rules='url'
                )
            +b.P.text.--size-xs.--color-light-blue.pa--t-sm {{ _('You can add links later in your account') }}
          photo-uploader.ma--t-sm(
            :max-files='1'
            :file-counter='false'
            :name="'avatar'"
            :error-text="errors && errors.avatar ? errors.avatar[0].message : ''"
            :field-label="_('Avatar')"
            :setValue=[]
            @photo-data="setAvatar($event)"
          )
        +b.nav-registration
          +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold.--pointer-black(
            @click='show(index - 1)'
          ) {{ _('Back') }}
          +e.P.next.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold.--pointer-black(
            @click='checkMedia(index + 1)'
          ) {{ _('Forward') }}
      +b.content(
        v-if='index === 4'
      )
        +b.VALIDATION-OBSERVER.data-block(
          ref='validator'
          v-slot="{ valid }"
          tag='form'
        )
          +e.content
            +b.P.video-label.text.--weight-bold.--size-sm.ma--b-xs.ma--b-none-xl {{ _('Surname') + '*' }}
            +b.D-CONTROL-INPUT.person-data-input(
              type='text'
              rules='required'
              name='lastName'
              v-model='form.lastName'
            )
          +e.content
            +b.P.video-label.text.--weight-bold.--size-sm.--font-gotham.ma--b-xs.ma--b-none-xl {{ _('City') + '*' }}
            +b.D-CONTROL-INPUT.person-data-input(
              rules='required'
              type='text'
              name='city'
              v-model='form.city'
            )
          +e.content
            +b.P.video-label.text.--weight-bold.--size-sm.--font-gotham.ma--b-xs.ma--b-none-xl {{ _('Phone') + '*' }}
            validation-provider(
              vid="phone"
              rules="required|realPhone"
              v-slot="{ errors }"
              slim
            )
              .relative.app-phone.app-phone--variant_registration
                MazPhoneNumberInput(
                  :default-country-code="currentCountryCode"
                  :default-phone-number="initialPhone"
                  name='phone'
                  v-model='form.phone'
                  :translations="phoneInputTranslation"
                  @blur="showPhoneError = true"
                  @focus="showPhoneError = false"
                )
                template(v-if="showPhoneError")
                +b.control-descriptor__errors(v-if="errors && errors.length")
                  +b.P.error-label {{ errors[0] }}
            //- +b.D-CONTROL-INPUT.person-data-input(
            //-   rules='phone|required'
            //-   name='phone'
            //-   vid='phone'
            //-   placeholder='+38(0__)___-__-__'
            //-   v-mask="['+38(0##)###-##-##']"
            //-   v-model='form.phone'
            //- )
          +e.content
            radio-bar.ma--b-sm.ma--t-sm(
              :name="'biometricPassport'"
              :radio-data='[{value: true, displayName: _("Є")}, {value: false, displayName: _("Немає")}]'
              @radio-value="setFormData($event, 'biometricPassport')"
              :setValue="false"
            )
              div(
                :class="{ 'ma--b-sm': !(errors && errors.biometricPassport) }"
              )
                +b.P.text.--color-black.--size-sm.--font-sans.--weight-bold {{ _('Закордонний біометричний паспорт') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.biometricPassport"
                ) {{ errors.biometricPassport[0].message }}
          +e.content
            +b.radio-block.ma--b-sm
              div(
                :class="{ 'ma--b-sm': !(errors && errors.knowledgeOfEnglish) }"
              )
                +b.P.text.--color-black.--size-sm.--font-sans.--weight-bold {{ _('Іноземна мова, якою вільно спілкуюсь на побутовому рівні') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.knowledgeOfEnglish"
                ) {{ errors.knowledgeOfEnglish[0].message }}
              row(
                :align="'center'"
              )
                cell(
                  :cols="'12'"
                  :style="{marginRight: '30px'}"
                )
                  row(
                    :align="['center']"
                  )
                    cell
                      +b-context('radio-block')
                        +e.INPUT.hidden-radio(
                          type='radio'
                          :checked="isKnowledgeLanguages"
                          :value='true'
                          :name="'isKnowledgeLanguages'"
                          @change="() => setFormData(true, 'isKnowledgeLanguages')"
                          :id="`'isKnowledgeLanguages' + ${true}`"
                        )
                        +e.LABEL.fake-radio(
                          :for="`'isKnowledgeLanguages' + ${true}`"
                        )
                    cell
                      +b-context('radio-block')
                        +e.LABEL.radio-label(
                          :for="`'isKnowledgeLanguages' + ${true}`"
                        )
                          +b.P.text.--size-sm.--color-black(
                            :class="{'text--weight-medium': isKnowledgeLanguages === true}"
                          ) {{ _("Володію") }}
                    cell(cols='12')
                      row(align="center").ma--t-xs.ma--l-lg
                        cell(cols="12")
                          checkbox-bar(
                            :key="`${knowledgeLanguages.includes('eng')}`"
                            :name="`knowledgeLanguagesEng`"
                            :message="_('Англійська')"
                            :value="'eng'"
                            :set-value="knowledgeLanguages.includes('eng')"
                            @checkbox-data="setKnowledgeLanguages($event, 'eng')"
                          )
                        cell(cols="12")
                          row(align="center")
                            cell
                              checkbox-bar(
                                :key="`${knowledgeLanguages.includes('other')}`"
                                :name="`knowledgeLanguages`"
                                :message="_('Інша')"
                                :value="'other'"
                                :set-value="knowledgeLanguages.includes('other')"
                                @checkbox-data="setKnowledgeLanguages($event, 'other')"
                              )
                            cell
                              +b.D-CONTROL-STATIC-INPUT.control-descriptor--normalize.person-data-input(
                                :rules="knowledgeLanguages.includes('other') ? 'required' : ''"
                                type='text'
                                name='knowledgeOfOtherLanguage'
                                v-model="form.knowledgeOfOtherLanguage"
                                :disabled="!knowledgeLanguages.includes('other')"
                              )

                cell(
                  :cols="'12'"
                  :style="{marginRight: '30px'}"
                )
                  row(
                    :align="['center']"
                  )
                    cell
                      +b-context('radio-block')
                        +e.INPUT.hidden-radio(
                          type='radio'
                          :checked="!isKnowledgeLanguages"
                          :value='false'
                          :name="'isKnowledgeLanguages'"
                          @change="() => { isKnowledgeLanguages = false; setKnowledgeLanguages(false, 'eng'); setKnowledgeLanguages(false, 'other') }"
                          :id="`'isKnowledgeLanguages' + ${false}`"
                        )
                        +e.LABEL.fake-radio(
                          :for="`'isKnowledgeLanguages' + ${false}`"
                        )
                    cell
                      +b-context('radio-block')
                        +e.LABEL.radio-label(
                          :for="`'isKnowledgeLanguages' + ${false}`"
                        )
                          +b.P.text.--size-sm.--color-black(
                            :class="{'text--weight-medium': isKnowledgeLanguages === false}"
                          ) {{ _("Не володію") }}

          +e.content
            radio-bar.ma--b-sm(
              :name="'driverLicense'"
              :radio-data='[{value: true, displayName: _("Є")}, {value: false, displayName: _("Немає")}]'
              :setValue="false"
              @radio-value="setFormData($event, 'driverLicense')"
            )
              div(
                :class="{ 'ma--b-sm': !(errors && errors.driverLicense) }"
              )
                +b.P.text.--color-black.--size-sm.--font-sans.--weight-bold {{ _('Права водія') + '*' }}
                +b.P.error-static(
                  v-if="errors && errors.driverLicense"
                ) {{ errors.driverLicense[0].message }}
          //- +e.content.ma--t-lg(
          //-   :style="{alignItems: 'flex-start'}"
          //- )
            +b.P.video-label.text.--weight-bold.--size-sm.--font-gotham {{ _('Social networks') }}
            +b.register-social-networks
              row
                cell.ma--b-xs(
                  :cols="'12'"
                )
                  row(
                    :align="'center'"
                  )
                    cell
                      +b.register-square(
                        :class="[form.facebookLink.length >= 1 ? 'register-square--facebook' : '']"
                      )
                        +b.APP-ICON.text.--color-white.--size-sm(
                          name="icon-facebook-square"
                        )
                    cell
                      d-control-input.thin-input.social-input(
                        rules='url'
                        v-model="form.facebookLink"
                      )
                cell.ma--b-xs(
                  :cols="'12'"
                )
                  row(
                    :align="'center'"
                  )
                    cell
                      +b.register-square(
                        :class="[form.instagramLink.length >= 1 ? 'register-square--instagram' : '']"
                      )
                        +b.APP-ICON.text.--color-white.--size-sm(name="icon-instagram")
                    cell
                      d-control-input.thin-input.social-input(
                        rules='url'
                        v-model="form.instagramLink"
                      )
                cell.ma--b-xs(
                  :cols="'12'"
                )
                  row(
                    :align="'center'"
                  )
                    cell
                      +b.register-square(
                        :class="[form.telegramLink.length >= 1 ? 'register-square--telegram' : '']"
                      )
                        +b.APP-ICON.text.--color-white.--size-sm(name="icon-telegram")
                    cell
                      d-control-input.thin-input.social-input(
                        rules='url'
                        v-model="form.telegramLink"
                      )
                cell.ma--b-xs(
                  :cols="'12'"
                )
                  row(
                    :align="'center'"
                  )
                    cell
                      +b.register-square(
                        :class="[form.tiktokLink.length >= 1 ? 'register-square--tiktok' : '']"
                      )
                        +b.APP-ICON.text.--color-white.--size-sm(
                          name="icon-tiktok"
                        )
                    cell
                      d-control-input.thin-input.social-input(
                        rules='url'
                        v-model="form.tiktokLink"
                      )
          +e.content
            +b.P.video-label.text.--weight-bold.--size-xs {{ _('') }}
          hr(
            :style="{width: '100%'}"
          )
          +b.agree-field
            validation-provider(
              vid="agree"
              :rules="{ required: { allowFalse: false } }",
              v-slot="{ errors }"
              v-model="form.agree"
              slim
              mode="eager",
            )
              checkbox-bar.privacy-policy(
                :name="'rules'"
                v-model="form.agree"
                :message="_('Я погоджуюсь з')"
                @checkbox-data="setFormData($event, 'agree')"
                :error="false"
              )
                template(#default)
                  +b.A.rules-link.text.--color-light-blue.--decoration-under(
                    :href='terms_link'
                    target='_blank'
                  ) {{ _('умови платформи') }}
                  | {{ " " +  _("та") + " " }}
                  +b.A.rules-link.text.--color-light-blue.--decoration-under(
                    :href='privacy_policy_link'
                    target='_blank'
                  ) {{ _('політикою конфіденційності') }}
                  | {{ " " + _('і підтверджую що мені вже є 18 років') }}
              +b.P.error-static(v-if="errors.length") {{ errors[0] }}

          validation-provider(
            vid="email"
            v-slot="{ errors }"
            slim
          )
            +b.P.text.--align-center.--color-red.--size-sm(v-if="errors.length") {{ errors[0] }}
          +b.nav-registration
            +e.P.prev.text.--color-green.--space-lg.--transform-upper.--size-xs.--weight-bold.--pointer-black(
              @click='show(index - 1)'
            ) {{ _('Back') }}
            +b.BUTTON.el-btn.--default.text.--color-white.--space-lg.--transform-upper(
              @click.prevent='prepareData(valid)'
              :disabled="isLoad"
              :style="{width: '174px', marginRight: '-30px'}"
            ) {{ _('Create') }}
</template>

<script>
import {
  characteristicsAPI,
  registerTalantAPI,
  generalInfoAPI,
  visualInfoAPI,
  bodyInfoAPI,
  clothesInfoAPI,
  mediaDataAPI,
} from '@requests/services/services'
import { MazPhoneNumberInput } from 'maz-ui'
// import { mask } from 'vue-the-mask'
import ModerationModal from '@app/modals/ModerationModal'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { disableAfterDate } from '@utils/helpers.js'
import 'maz-ui/lib/css/base.css'
import 'maz-ui/lib/css/maz-input-tags.css'
import 'maz-ui/lib/css/maz-phone-number-input.css'

export default {
  name: 'talant-registration',
  props: {
    name: String,
    email: String,
    terms_link: String,
    privacy_policy_link: String,
  },
  // directives: {
  //   mask,
  // },
  mixins: [FormMixin],
  components: {
    MazPhoneNumberInput,
  },
  data() {
    return {
      currentCountryCode: 'UA',
      initialPhone: '',
      showPhoneError: false,
      phoneInputTranslation: {
        countrySelectorLabel: this._('Країна'),
        countrySelectorError: this._('Пошук'),
        phoneNumberLabel: this._('Номер телефону'),
        example: this._('Приклад:'),
      },
      professionalPhotoExperience: [
        {
          value: 0,
          displayName: this._('Не має досвіду'),
        },
        {
          value: 1,
          displayName: this._('Маю невеликий досвід'),
          additionalInfo: this._('(було декілька комерційних проектів)'),
        },
        {
          value: 2,
          displayName: this._('Маю великий досвід'),
          additionalInfo: this._('(було багато комерційних зйомок)'),
        },
      ],
      professionalVideoExperience: [
        {
          value: 0,
          displayName: this._('Не має досвіду'),
        },
        {
          value: 1,
          displayName: this._('Маю невеликий досвід'),
          additionalInfo: this._('(було декілька комерційних проектів)'),
        },
        {
          value: 2,
          displayName: this._('Маю великий досвід'),
          additionalInfo: this._('(було багато комерційних зйомок)'),
        },
      ],
      errors: null,
      testApi: characteristicsAPI,
      avatar: [],
      substep: true,
      tabs: [
        {
          title: this._('Verification'),
          visible: false,
        },
        {
          title: this._('General information'),
          visible: true,
        },
        {
          title: this._('Visual data'),
          visible: false,
        },
        {
          title: this._('Clothes'),
          visible: false,
        },
        // {
        //   title: this._('Photo & video'),
        //   visible: false,
        // },
        {
          title: this._('Контакти та додаткова інформація'),
          visible: false,
        },
      ],
      activeTab: 1,
      filters: {},
      isKnowledgeLanguages: 0,
      knowledgeLanguages: [],
      knowledgeOfOtherLanguage: '',
      form: {
        gender: null,
        birthday: null,
        skills: null,
        // step 2
        eyesColor: null,
        hairType: null,
        hairColor: null,
        weight: null,
        // step 3
        bodyType: null,
        skinColor: null,
        specials: [],
        // step 4
        clothingSizeTop: null,
        clothingSizeBottom: null,
        shoeSize: null,
        height: null,
        // step 5
        photos: [],
        videoPresentation: '',
        abilityVideo: '',
        // step 6
        lastName: null,
        city: null,
        phone: null,
        agree: null,
        email: null,
        biometricPassport: false,
        driverLicense: false,
        knowledgeOfEnglish: false,
        knowledgeOfOtherLanguage: '',
        // facebookLink: '',
        // instagramLink: '',
        // telegramLink: '',
        // tiktokLink: '',
      },
    }
  },
  mounted() {
    this.getFilters()
    this.form.email = this.email
    this.$refs.topOfContent.scrollIntoView({ behavior: 'smooth' })
  },
  methods: {
    disableAfterDate,
    setKnowledgeLanguages(e, val) {
      if (e) {
        this.isKnowledgeLanguages = true
        this.knowledgeLanguages.push(val)
      } else {
        this.knowledgeLanguages = this.knowledgeLanguages.filter(el => el !== val)
        if ('other' === val) {
          this.form.knowledgeOfOtherLanguage = ''
        }
      }
    },
    back() {
      this.scrollTop()
      this.substep = true
    },
    show(index) {
      this.tabs.forEach(el => {
        el.visible = false
      })
      this.tabs[index].visible = !this.tabs[index].visible
      this.activeTab = index
      this.scrollTop()
    },
    getFilters() {
      characteristicsAPI.execute().then(({ data: { item } }) => {
        this.filters = item
      })
    },
    setFormData(value, field) {
      this.form[field] = value

      this.$set(this.errors, field, null)
    },
    setAvatar(e) {
      this.avatar = e
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
    },
    checkGeneral(index) {
      this.isLoad = true
      const data = {
        gender: this.form.gender,
        birthday: this.form.birthday,
        skills: this.form.skills,
        professionalPhotoExperience: this.form.professionalPhotoExperience,
        professionalVideoExperience: this.form.professionalVideoExperience,
      }
      generalInfoAPI
        .execute({}, data)
        .then(() => {
          this.show(index)
          this.errors = null
          this.scrollTop()
        })
        .catch(e => e.json())
        .then(er => {
          if (er) {
            this.errors = er.errors[0].state
          }
        })
        .finally(() => {
          this.isLoad = false
        })
    },
    checkVisualData() {
      const data = {
        hairType: this.form.hairType,
        hairColor: this.form.hairColor,
        eyesColor: this.form.eyesColor,
        weight: 0 === this.form.weight ? null : this.form.weight,
      }
      visualInfoAPI
        .execute({}, data)
        .then(() => {
          this.substep = false
          this.errors = null
          this.scrollTop()
        })
        .catch(e => e.json())
        .then(er => {
          if (er) {
            this.errors = er.errors[0].state
          }
        })
    },
    checkBodyData(index) {
      const data = {
        bodyType: this.form.bodyType,
        skinColor: this.form.skinColor,
        specials: this.form.specials,
      }
      bodyInfoAPI
        .execute({}, data)
        .then(() => {
          this.show(index)
          this.errors = null
          this.scrollTop()
        })
        .catch(e => e.json())
        .then(er => {
          if (er) {
            this.errors = er.errors[0].state
          }
        })
    },
    checkClothData(index) {
      const data = {
        clothingSizeTop: this.form.clothingSizeTop,
        clothingSizeBottom: this.form.clothingSizeBottom,
        shoeSize: this.form.shoeSize,
        height: this.form.height,
      }
      clothesInfoAPI
        .execute({}, data)
        .then(() => {
          this.show(index)
          this.errors = null
          this.scrollTop()
        })
        .catch(e => e.json())
        .then(er => {
          if (er) {
            this.errors = er.errors[0].state
            console.warn(this.errors)
          }
        })
    },
    checkMedia(index) {
      const data = {
        photos: this.form.photos,
        avatar: this.avatar,
        videoPresentation: this.form.videoPresentation,
        abilityVideo: this.form.abilityVideo,
      }
      mediaDataAPI
        .execute({}, data)
        .then(() => {
          this.show(index)
          this.errors = null
          this.scrollTop()
        })
        .catch(e => e.json())
        .then(er => {
          if (er) {
            this.errors = er.errors[0].state
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator[0].setErrors(parsed)
    },
    async prepareData() {
      this.showPhoneError = true
      this.$refs.validator[0].validate().then(valid => {
        if (!valid) return
        this.submit(valid, this.form)
      })
    },
    send(data) {
      this.showPhoneError = true
      data.avatar = this.avatar
      this.form.knowledgeOfEnglish = this.knowledgeLanguages.includes('eng')
      return registerTalantAPI.execute({}, data).then(() => {
        this.$modal.show(
          ModerationModal,
          { info: this.$attrs },
          { classes: ['vm--modal_md'], adaptive: true, height: 'auto' },
          {
            closed: () => {
              window.location.href = '/auth/login/'
            },
          }
        )
      })
    },
  },
}
</script>
<style scoped>
.nav-registration__prev {
  cursor: pointer;
}
.nav-registration__next {
  cursor: pointer;
}
.nav-registration__prev:hover {
  color: #5CA300
}
.nav-registration__next:hover {
  color: #5CA300
}
</style>
