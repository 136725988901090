<template lang="pug">
  include ../../Bemto
  light-box(
    :source='links'
  )
    row
      cell(
        v-for='(item, idx) in data'
        :key='idx'
        :cols="['4', 'narrow-sm']"
      )
        template(v-slot:default='{ show }')
          picture
            source(:srcset='item.imageLinks.webp' type='image/webp')
            img.photo-reference(
              :src='item.imageLinks.base'
              @click='show(idx)'
              alt='avatar'
            )
</template>

<script>
export default {
  name: 'photo-preview',
  props: {
    data: Array,
  },
  data() {
    return {
      links: [],
    }
  },
  mounted() {
    this.getLinks()
  },
  methods: {
    getLinks() {
      this.data.forEach(el => {
        this.links.push(el.imageLinks.base)
      })
    },
  },
}
</script>
