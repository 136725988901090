import { clientShortList } from '@requests/services/services'

export const shortList = {
  namespaced: true,
  state: {
    shortList: [],
  },
  getters: {
    getShortList: state => state.shortList,
  },
  actions: {
    shortListRequest({ commit }) {
      return clientShortList.execute().then(({ shortlist: shortListData }) => {
        commit('setShortList', shortListData)
      })
    },
  },
  mutations: {
    setShortList: (state, response) => { state.shortList = response },
  },
}
