<template lang="pug">
  include ../Bemto
  div(
    v-bem=''
    :class="[inputClass, {'d-control-input--icon_prepend': prependIcon, 'd-control-input--icon_append': appendIcon}]"
  )
    //- div(v-bem.element-wrap='')
    // FIXME: return v-bem.element=''
    component(
      v-if="prependIcon"
      :is="prependComponent"
      :prepend-icon="prependIcon"
      :prepend-icon-func="prependIconFunc"
    )
    component.d-control-input__element(
      :class="{'d-control-input__element--icon_prepend': prependIcon, 'd-control-input__element--icon_append': appendIcon}"
      :is="element",
      :value.prop='value',
      ref='input'
      v-on="listeners"
      :id='id',
      v-bind='$attrs'
      :type='type'
      v-model='model',
    )
      template(
        v-slot:[name]="binded"
        v-for="(name, key) in eSlots"
      )
        slot(
          v-bind="binded"
          :name="key"
        )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-tooltip='isTooltip'
      :tooltip-text='tooltipText'
      :is-required='required'
      :hint="hint"
      :target="target"
      v-if="inputLabel"
    )
    component(
      v-if="appendIcon"
      :is="appendComponent"
      :append-icon="appendIcon"
      :append-icon-func="appendIconFunc"
    )
    +b.d-control-input__icon-password(
      v-if='type == "password"'
      @click.prevent='changeType()',
    )
      +b.APP-ICON(
        size="lg"
        :key="!show ? 'icon-eye-on' : 'icon-eye-off'"
        :name="!show ? 'icon-eye-on' : 'icon-eye-off'"
      )
</template>

<script>

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'prependComponent',
    'prependIcon',
    'prependIconFunc',
    'appendComponent',
    'appendIcon',
    'appendIconFunc',
    'inputClass',
    'labelComponent',
    'id',
    'value',
    'labelText',
    'isTooltip',
    'tooltipText',
    'inputLabel',
    'required',
    'providerContext',
    'type',
    'element',
    'mask',
    'clearMaskOnLostFocus',
    'hint',
    'target',
  ],
  block: 'd-control-input',
  data() {
    return {
      input: '',
      show: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit('input', this.getEvent($event)),
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    getEvent($event) {
      if ($event && $event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },
}
</script>
