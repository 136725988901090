var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.videoSrc)?_c('div',{staticClass:"video-player"},[_c('video',{attrs:{"id":"video","src":_vm.videoSrc,"preload":"metadata"}}),_c('div',{staticClass:"controls"},[_c('button',{staticClass:"play",attrs:{"id":"play-pause"}}),_c('span',{staticClass:"time",attrs:{"id":"current-time"}},[_vm._v("0:00")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"volume-container",on:{"mouseenter":_vm.enterIcon,"mouseleave":_vm.leaveIcon}},[_c('button',{class:['volume-button', {
        'volume-button_no-sound': Number(_vm.videoVolume) === 0
      }],attrs:{"id":"volume-button"},on:{"click":_vm.volumeIconClick}}),_c('div',{class:['volume-container_bar', {
        'volume-container_bar-active': _vm.isVolumeBarVisible,
        'volume-container_bar-pointer': _vm.isVolumeBarVisible || _vm.isEnterVolumeIcon
      }],on:{"mouseenter":_vm.enterMouse,"mouseleave":_vm.leaveMouse}},[_c('input',{attrs:{"type":"range","id":"volume-bar","min":"0","max":"1","step":"0.01","value":"1"}})])]),_c('button',{attrs:{"id":"fullscreen-button"}})])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-container"},[_c('input',{attrs:{"type":"range","id":"progress-bar","min":"0","max":"100","value":"0"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time"},[_c('span',{staticClass:"time",attrs:{"id":"total-time"}},[_vm._v("0:00")])])
}]

export { render, staticRenderFns }