/* eslint-disable prefer-destructuring, prefer-const */
import { evolve } from 'ramda'
import qs from 'qs'

const dateFrom = date => new Date(date).toISOString()
export const dateRangeFrom = evolve({ starts: dateFrom, ends: dateFrom })

export function parsePaginationUrl() {
  const { search } = window.location
  if (search.includes('limit')) {
    const limit = search.match(/limit=(\d+$)/)
    const offset = search.match(/offset=(\d+$)/)
    return {
      limit: limit ? Number(limit[1]) : null,
      offset: offset ? Number(offset[1]) : null,
    }
  }
  return {}
}

export function friendlyUrlParser() {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl = {}
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]
    const hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      let [key, val] = hash.split('=')
      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        val = val.split(',')
        val.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
      return false
    })
  }
  return parsedUrl
}

/**
 * @param {string} url
 */
export const queryStringParser = url => {
  const priceKeys = {
    0: 'min',
    1: 'max',
  }

  /**
   * Parse url for creating an object of selected attributes
   * `ignoreQueryPrefix` - this option removes the `?` character from url
   */
  const parsed = qs.parse(url, { ignoreQueryPrefix: true, comma: true })

  const parseRangeFilter = (acc, key) => {
    Object.keys(priceKeys).forEach(index => {
      if (key.includes(priceKeys[index])) {
        /**
         * Remove `lte` and `gte` postfixes from attribute name
         */
        const clearKey = key.replace(`__${priceKeys[index]}`, '')

        /**
         * Create a new array if it doesn't exist
         */
        if (!acc[clearKey]) {
          acc[clearKey] = []
        }

        /**
         * Add min and max values to an array of range filter by right indexes
         */
        acc[clearKey] = parsed[key]
      }
    })
    return acc
  }

  const prettified = Object.keys(parsed).reduce((acc, key) => {
    if (key.includes('min') || key.includes('max')) {
      acc = parseRangeFilter(acc, key)
    } else if ('limit' !== key && 'offset' !== key) {
      acc[key] = parsed[key]
    }
    return acc
  }, {})
  return prettified
}
