<template lang="pug">
include ../Bemto
+b.registration-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap
  +b.P.text.header-form-text.--align-center.--size-sm.--color-dark-gray(
  ) {{ _('Введіть дані для створення акаунту') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '12px'}"
    ref='validator'
    v-slot="{ handleSubmit, valid }"
    slim
  ): form(@submit.prevent='handleSubmit(awaitCaptcha)')
    validation-provider(
      vid="recaptcha"
      v-slot="{ errors }"
    )
      captcha.control-descriptor--clear(
        v-model='form.recaptcha'
        ref='captcha'
        @verify='saveCaptcha($event)'
      )
      +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
        v-if='errors && errors.length'
      ) {{ errors[0] }}
    d-control-input(
      autofocus
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      v-model='form.name'
      :rules="'required'"
      name='name'
      :placeholder="_('Ім’я та Прізвище')"
    )
    d-control-input(
      autofocus
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|email'"
      v-model='form.email'
      type='email'
      name='email'
      :placeholder="_('Електронна пошта')"
    )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      v-model='form.password'
      name='password'
      type='password'
      :rules="'required|min:8'"
      :placeholder="_('Пароль')"
    )
    agreement-checkbox(:onChange="handleChangeCheckbox" :rules="'required'")

    +b.P.text.--align-center.--color-red.--size-sm(
      v-if='nonFieldErrors'
    ) {{ nonFieldErrors[0].message }}
    +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.form-submit-button.--weight-bold(
      @click.prevent="awaitCaptcha"
      type='submit'
      :disabled='isLoad || !form.isChecked || !form.name || !form.email || !form.password'
    ) {{ _('Створити акаунт') }}
    +b.P.social-auth-title.text.--color-black.--align-center.--transform-upper.--size-sm {{ _('Або') }}
      row.ma--t-sm(
        justify='center'
      )
        cell(
          :cols="'12'"
        )
          +b.A.social-auth.--google(
            :href='googleLink'
          )
            img(src='/static/img/google.png')
            +b() {{ ('Продовжити з Google') }}
    div(
      v-for='item in authMessages'
    )
      +b.P.text.--align-center.--color-red.--size-sm {{ item }}
    login-reference-text
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { signUpClientAPI } from '@requests/services/services'

const Captcha = () => import('@app/Forms/Captcha')

export default {
  name: 'registration-client',
  mixins: [
    FormMixin,
  ],
  components: { Captcha },
  props: {
    regLink: {},
    googleLink: {},
    facebookLink: {},
    authMessages: {
      default: () => ([]),
    },
    nextUrl: String,
  },
  data() {
    return {
      nonFieldErrors: null,
      form: {
        isChecked: false,
        name: '',
        email: null,
        password: null,
      },
    }
  },
  methods: {
    saveCaptcha(captcha) {
      this.form.recaptcha = captcha
      return this.prepareData()
    },
    updateValidator(errors) {
      this.$refs.captcha.reset()
      this.nonFieldErrors = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    awaitCaptcha() {
      if (this.$refs.captcha) {
        this.$refs.captcha.reload()
      }
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return null;
        return this.submit(valid, this.form)
      });
    },
    send(data) {
      const splittedName = data.name.split(' ')

      const formdata = JSON.parse(JSON.stringify({
        email: data.email,
        firstName: splittedName?.[0] ?? '',
        lastName: splittedName?.[1] ?? ' ',
        password: data.password,
        agree: true,
      }))

      formdata.email = formdata.email.toLowerCase()

      return signUpClientAPI.execute({}, formdata).then(({ data: { item: { redirect } } }) => {
        window.location.href = redirect
      })
      // .catch(() => {
      //   this.isLoading = false
      // })
    },
    handleChangeCheckbox(isChecked) {
      this.form.isChecked = isChecked
    },
  },
}
</script>
<style lang="scss">
.control-descriptor__errors {
  background-color: white !important;
  color: black !important;

  padding: 8px 12px !important;
  max-width: none !important;
  width: fit-content !important;

  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1) !important;
  border: 1px solid #F2F2F2 !important;
  top: calc(100% - 9px) !important;
  right: 8px !important;

  &::after {
    display: none;
  }
}

.d-control-input {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.8px !important;
  text-align: left;
}
</style>
<style scoped>
.header-form-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;

  width: 100%;

  margin-bottom: 4px;
}

.form-submit-button {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;

  height: 40px;
}

.el-btn {
  &:hover {
    background: #5CA300;
    color: white;
  }
}
</style>
