<template lang="pug">
  include ../../Bemto
  div
    +b.checkbox-label(
      v-for="(item, index) in options"
      :style="{marginBottom: '15px'}"
    )
      +e.INPUT(
        type='checkbox'
        :name="'other'"
        :id="'other_' + index"
        v-model='checked'
        :value='item.value'
        @change='submit'
        :checked="checked.includes(item.value)"
      )
      //- +e.wrapper
      //-   +b-context('checkbox-block')
      //-     +e.LABEL.fake-checkbox(
      //-       :for="'other_' + index"
      //-     )
      +b.LABEL.text.--size-sm.--pointer(
        :class="{'text--weight-bold': checked.includes(item.value)}"
        :for="'other_' + index"
      )
        +b.SPAN {{ item.displayName }}
</template>
<script>
export default {
  name: 'OtherFilterWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
      options: [
        {
          value: 'biometric_passport',
          displayName: this._('Закородонний паспорт (біометрія)'),
        },
        {
          value: 'driver_license',
          displayName: this._('Права водія'),
        },
        {
          value: 'language',
          displayName: this._('Іноземна мова '),
        },
      ],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = nval
      },
    },

    // filter: {
    //   deep: true,
    //   immediate: true,
    //   handler(nval) {
    //     if (nval && nval.values) {
    //       this.choicesLocal = nval.values
    //     }
    //   },
    // },
  },

  mounted() {
    this.checked = this.value
  },

  methods: {
    submit() {
      this.$emit('input', this.prepare(this.checked))
    },
    prepare(checked) {
      return checked.reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {})
    },

    normalize(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.options
        .filter(el => val.find(v => Number(v) === el.value)).map(el => el.value)
      return checked
    },

    // searchAttrs() {
    //   this.$nextTick(() => {
    //     const choices = JSON.parse(JSON.stringify(this.filter.values))
    //     this.choicesLocal = choices.filter(choice => {
    //       const searchVal = this.searchValue.toLowerCase()
    //       const choiceName = choice.values[0].toLowerCase()
    //       if (choiceName.includes(searchVal)) {
    //         return choice
    //       }
    //       return false
    //     })
    //   })
    // },
  },
}
</script>
<style scoped>
.checkbox-label input[type="checkbox"] {
  appearance: none; /* Remove default styling */
  width: 20px;
  height: 20px;
  border: 1.5px solid #CCCCCC;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #7069A9;
  border: none;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px; /* Adjust size as needed */
  height: 16px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.394 4.33312L6 12.7271L1.606 8.33312L2.66666 7.27246L6 10.6058L13.3333 3.27246L14.394 4.33312Z" fill="white"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
