import { without } from 'ramda'
import {
  favoriteIdListApi,
  addToFavoriteApi,
  removeFavoriteTalentsAPI,
} from '@requests/services/services'

import {
  favoritesListResource,
} from '@requests/services/catalog'

export const favoriteList = {
  namespaced: true,
  state: {
    favoriteList: [],
    favoriteListId: [],
  },
  getters: {
    getFavoriteList: state => state.favoriteList,
    getFavoriteListId: state => state.favoriteListId,
    getTotalTalents: state => {
      let result = 0
      state.favoriteList.forEach(el => {
        result += el.talents.length
      })
      return result
    },
  },
  actions: {
    favoriteListRequest({ commit }) {
      return favoritesListResource.execute().then(({ data: { items } }) => {
        commit('setFavoriteList', items)
      })
    },
    favoriteListIdRequest({ commit }) {
      return favoriteIdListApi.execute().then(({ data: { items } }) => {
        commit('setFavoriteListId', items)
      })
    },
    async addTalent({ commit, state }, payload) {
      await addToFavoriteApi.execute({}, payload)
      commit('setFavoriteListId', [...state.favoriteListId, ...payload.talents])
    },
    async removeTalent({ commit, state }, payload) {
      await removeFavoriteTalentsAPI.execute(payload)
      commit('setFavoriteListId', without(payload.ids, state.favoriteListId))
    },
  },
  mutations: {
    setFavoriteList: (state, response) => { state.favoriteList = response },
    setFavoriteListId: (state, response) => { state.favoriteListId = response },
  },
}
