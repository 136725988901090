<template lang="pug">
include ../../Bemto
+b.telegram-prompt
  +b.P.text.--size-sm {{ _('All communication with the site administration will take place in a telegram.') }}
  +b.SPAN.text.--weight-bold.--size-sm.rules-link {{ _('A subscription to the chatbot is required.') }}
  hr
  row(
    :align="'center'"
    :justify="'center'"
  )
    cell
      +b.APP-ICON.text.--color-light-blue.--size-xs(
        name="icon-telegram"
      )
    cell
      +b.A.rules-link.text.--color-light-blue.--transform-upper.--decoration-under.--space-lg.--size-xs.--pointer(
        target='_blank'
        :href='BOT_LINK'
      ) {{ _('Subscribe to chatbot') }}
</template>

<script>
export default {
  name: 'telegram-prompt',
  data() {
    return {
      BOT_LINK: window.BOT_LINK,
    }
  },
}
</script>
