<template lang="pug">
include ../../Bemto
project-edit(
  @close-bar="hideBar"
  :is-visible='barVisible'
  :show-to-archive="showToArchive"
  :isEdit='isEdit'
  @project-to-archive="$emit('project-to-archive')"
  @watch-project="$emit('watch')"
  @edit-project="$emit('edit')"
  @remove-project="$emit('remove')"
)
  +b.project-btn(
    :class="{'is-active': barVisible}"
    @click.prevent="toggleBar"
  )
    +e.dot
    +e.dot
    +e.dot
</template>

<script>
export default {
  name: 'edit-template',
  props: {
    showToArchive: {
      default: true,
    },
    isEdit: {
      default: true,
    },
  },
  data() {
    return {
      barVisible: false,
    }
  },
  methods: {
    hideBar() {
      this.barVisible = false
    },
    toggleBar() {
      this.barVisible = !this.barVisible
    },
  },
}
</script>
