<template lang="pug">
include ../Bemto
+b.verification-wrapp: +e.wrap
  +b.P.text.--weight-bold.--color-black.--font-serif.--size-2lg.--size-xl-sm {{ _("Almost done!") }}
  +b.P.text.--size-sm.--color-dark-gray.ma--t-lg {{ _('On your email') }}
  +b.P.text.--weight-bold.--color-dark-gray.pa--t-xs.pa--b-xs {{ userData.email }}
  +e.P.confirm-text.text.--align-center.--size-sm.--color-dark-gray {{ _('we sent an email, just click on the link in it to finish creating your account') }}
  +b.IMG.pa--t-xl.pa--b-2xs(src='/static/img/letter.png')
  +b.P.text.--size-sm.--color-dark-gray {{ _('No letter ?') }}
  +b.P.text.--size-sm.--color-light-blue.--pointer-black.ma--t-sm.ma--b-sm(
    @click="$emit('send-again')"
  ) {{ _('Send again') }}

</template>

<script>

import { verificationAPI } from '@requests/services/services'

export default {
  props: {
    userData: Object,
  },
  name: 'confirm-email',
  methods: {
    sendAgain(data) {
      verificationAPI.execute({}, data).then(() => {
        this.$notify(
          {
            text: this._('Email sended'),
            type: 'success',
          }
        )
      })
    },
  },
}
</script>
