<template lang="pug">
include ../Bemto
+b.verification-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap.container
  +e.P.title.text.--align-center.--weight-bold.--size-xl.--font-serif.title {{ _("Password reset") }}
  +b.P.text.--align-center.--size-sm.subtitle {{ _('Choose security password. We recommend using capital letters and special characters.') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '24px'}"
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|min:8'"
      v-model='form.password'
      type='password'
      name='password'
      :placeholder="_('Password')"
    )
    d-control-input(
      :style="{marginTop: '-25px', marginBottom: '16px'}"
      :class="'register-input'"
      :rules="'required|min:8|confirmed:password'"
      v-model='form.password2'
      type='password'
      name='password2'
      :placeholder="_('Repeat password')"
    )
    +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.button-wrapper(
      @click.prevent="prepareData(valid)"
    ) {{ _('Створити') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { resetPasswordConfirmAPI } from '@requests/services/services'

export default {
  mixins: [
    FormMixin,
  ],
  name: 'password-reset',
  props: {
    code: {},
    token: {},
  },
  data() {
    return {
      form: {
        password: null,
        password2: null,
        code: this.code,
        token: this.token,
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      return resetPasswordConfirmAPI.execute({}, data).then(() => {
        window.location.pathname = '/auth/login/'
      })
    },
  },
}
</script>
<style scoped>
  .button-wrapper {
    text-decoration: none;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
  }

  .button-wrapper:hover {
    background-color: #5CA300;
  }

  .container {
    padding: 40px 16px;
  }

  .subtitle {
    max-width: 378px;
    padding-top: 8px;
  }

  .title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  @media (max-width: 805px) {
    .container {
      margin: 0 16px;
      padding: 40px 24px;
    }
  }
</style>
