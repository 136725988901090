import commons from './commons'
import triggers from './triggers'
import Controls from './Controls'
import errorPages from './error-pages'
import registration from './registration'
import authorization from './authorization'
import pages from './pages'
import Pagination from './Pagination'
import Catalog from './Catalog'
import List from './List'
import VideoBlock from './VideoBlock';
import FavoriteIcon from './FavoriteIcon';

export default function iComponents(Vue) {
  Vue.use(commons)
  Vue.use(triggers)
  Vue.use(Controls)
  Vue.use(errorPages)
  Vue.use(registration)
  Vue.use(authorization)
  Vue.use(pages)
  Vue.use(Pagination)
  Vue.use(Catalog)
  Vue.use(List)
  Vue.use(VideoBlock)
  Vue.use(FavoriteIcon)
}
