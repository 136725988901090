import TalantRegistration from './TalantRegistration'
import Verification from './Verification'
import EmailConfirm from './EmailConfirm'
import CreatePassword from './CreatePassword'
import ResetPassword from './ResetPassword'
import RegistrationRange from './RegistrationRange'

export default function install(Vue) {
  Vue.component(TalantRegistration.name, TalantRegistration)
  Vue.component(Verification.name, Verification)
  Vue.component(EmailConfirm.name, EmailConfirm)
  Vue.component(CreatePassword.name, CreatePassword)
  Vue.component(RegistrationRange.name, RegistrationRange)
  Vue.component(ResetPassword.name, ResetPassword)
}
