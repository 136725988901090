<template lang="pug">
  include ../../Bemto
  div
    +b.g-row.--space_xs.--appearance_spaced
      +b.g-cell(v-for="attr in 4")
        +b.LABEL.d-control-checkbox.--variant_1(
          :style="{'--checker-color': 'red'}"
        )
          +e.INPUT.element(
            :name="'attr.title'"
            type='checkbox'
            :value="'test'"
            v-model='formdataLocal'
            @change="$emit('input', formdataLocal)"
          )
          +e.label.is-visible-xl(
            v-tooltip.auto="{content: 'red'}"
          )
          +e.label.is-hidden-xl
</template>

<script>
export default {
  name: 'ColorWidget',

  props: {
    filter: {},
    value: {},
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.formdataLocal = nval
      },
    },
  },

  data() {
    return {
      isDesktop: false,
      formdataLocal: [],
    }
  },
}
</script>
