<template lang="pug">
include ../../Bemto
div
  +b.application-card-mobile
    +e.wrapper
      picture(
        @mouseover="talent.secondPreviewImage.base ? hover = true : hover = false"
        @mouseleave="hover = false"
      )
        template(v-if="talent.firstPreviewImage.base")
          template(v-if="talent.secondPreviewImage.webp ")
            +e.SOURCE(:srcset="hover ? talent.secondPreviewImage.webp : talent.firstPreviewImage.webp")
            +e.IMG.photo(:src="hover ? talent.secondPreviewImage.base : talent.firstPreviewImage.base", :alt='talent.firstName')
          template(v-else)
            +e.SOURCE(:srcset="talent.firstPreviewImage.webp")
            +e.IMG.photo(:src="talent.firstPreviewImage.base", :alt='talent.firstName')
        +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName')
      +e.data
        +b.A.text.--font-serif.--size-2sm.--weight-bold.--pointer-green.--color-black(
          :href='talent.link'
        ) {{ talent.firstName }}
        row.ma--t-2sm(
          :align="'center'"
        )
          cell
            +b.P.text.--size-xs ID: {{ talent.id }}
          cell
            +b.P.text.--color-light-gray.--size-xs |
          cell
            +b.P.text.--size-xs {{ _('Age') + ': ' + talent.age }}
        row.ma--t-sm
          cell.ma--b-2xs(
            v-for='skill, index in talent.skills'
            :key='`skill_${talent.id}_${skill}`'
          )
            +b.panel.--br-xl.--bg-light-gray.pa--l-xs.pa--r-xs
              +b.P.text.--align-center.--size-xs.--weight-bold {{ singleChar('skills', skill) }}
          cell.ma--b-2xs(
            v-for='special, index in talent.specials'
            :key='`special_${talent.id}_${special}`'
          )
            +b.panel.--br-xl.--bg-light-gray.pa--l-xs.pa--r-xs
              +b.P.text.--align-center.--size-xs.--weight-bold {{ singleChar('specials', special) }}
    +e.controls
      +e.checkbox
        +e.INPUT.hidden-checkbox(
          type='checkbox'
          :name='talent.id'
          :id='talent.id'
          v-model='value'
          @change="$emit('mark-data', value, talent.id)"
        )
        +e.LABEL.fake-checkbox(
          :for='talent.id'
        )
      +e.DIV(
        v-if='talent.isSubscribed'
      )
        +e.P.send-request.text.--pointer-green.--size-xs(
          v-if='requestStatus == null'
          @click="$emit('bot-request', [talent.id])"
        ) {{ _('Send request') }}
        +b.talent-request-status(
          :class="[statuses[requestStatus]]"
          :key="[statuses[requestStatus]]"
          v-else
        )
          +b.P.text.--size-sm.--weight-medium.--lh-sm {{ talentRequestStatus('talentRequestResponses', requestStatus) }}
      +e.P.t.text.--color-red.--size-xs(
        v-else
      ) {{ _('Не подписан на чат-бота') }}
      +e.P.remove.text.--pointer-green.--size-xs(
        @click="$emit('remove-talent', talent.id)"
      ) {{ _('Remove')}}

  +b.application-card-desktop(
    v-if='characteristic'
  )
    +e.PICTURE.picture(
      @mouseover="talent.secondPreviewImage.base ? hover = true : hover = false"
      @mouseleave="hover = false"
    )
      template(v-if="talent.firstPreviewImage.base")
        template(v-if="talent.secondPreviewImage.webp ")
          +e.SOURCE(:srcset="hover ? talent.secondPreviewImage.webp : talent.firstPreviewImage.webp")
          +e.IMG.photo(:src="hover ? talent.secondPreviewImage.base : talent.firstPreviewImage.base", :alt='talent.firstName')
        template(v-else)
          +e.SOURCE(:srcset="talent.firstPreviewImage.webp")
          +e.IMG.photo(:src="talent.firstPreviewImage.base", :alt='talent.firstName')
      +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName')
    +e.data
      +b.A.text.--font-serif.--size-lg.--weight-bold.--color-black.--pointer-green(
        :href='talent.link'
      ) {{ talent.firstName }}
      row.ma--t-2xs
        cell
          +b.P ID: {{ talent.id }}
        cell
          +b.P.text.--color-light-gray |
        cell
          +b.P {{ _('Age') + ': ' + talent.age }}
      row
        cell.ma--t-2xs(
          v-for='(skill, index) in talent.skills'
          :key='`skill_${talent.id}_${skill}_${index}`'
        )
          +b.panel.--br-xl.--bg-light-gray.pa--l-xs.pa--r-xs
            +b.P.text.--size-xs.--weight-medium {{ singleChar('skills', skill) }}
        cell.ma--t-2xs(
          v-for='special, index in talent.specials'
          :key='`special_${talent.id}_${special}_${index}`'
        )
          +b.panel.--br-xl.--bg-light-gray.pa--l-xs.pa--r-xs
            +b.P.text.--size-xs.--weight-medium {{ singleChar('specials', special) }}
    +e.controls
      +e.DIV(
        v-if='talent.isSubscribed'
      )
        +e.P.send-request.text.--pointer-green(
          v-if='requestStatus == null'
          @click="$emit('bot-request', [talent.id])"
        ) {{ _('Send request') }}
        +b.talent-request-status(
          :class="[statuses[requestStatus]]"
          v-else
        )
          +b.P.text.--size-sm.--weight-medium.--lh-sm {{ talentRequestStatus('talentRequestResponses', requestStatus) }}
      +e.P.t.text.--color-red(
        v-else
      ) {{ _('Не подписан на чат-бота') }}
      +e.line
      +e.P.remove.text.--pointer-green(
        @click="$emit('remove-talent', talent.id)"
      ) {{ _('Remove')}}
    +e.mark
      +e.INPUT.hidden-checkbox(
        type='checkbox'
        :name='talent.id'
        :id='talent.id'
        v-model='value'
        @change="$emit('mark-data', value, talent.id)"
      )
      +e.LABEL.fake-checkbox(
        :for='talent.id'
      )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'application-card',
  props: {
    talent: {},
    state: {},
    requestStatus: {
      default: null,
    },
  },
  data() {
    return {
      hover: false,
      value: null,
      statuses: {
        0: 'talent-request-status--sent',
        1: 'talent-request-status--available',
        2: 'talent-request-status--not-available',
      },
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getCharacteristic',
      singleChar: 'getSingleCharacteristic',
    }),
    ...mapGetters('projectSelects', {
      talentRequestStatus: 'getSingleSelect',
    }),
  },
  watch: {
    state() {
      this.value = this.state
    },
  },
}
</script>
