<template lang="pug">
include ../Bemto
input.search-talent(
  name='name'
  v-model='form.name'
  :placeholder="_('Talent name')"
  @input="$emit('input',{'name': form.name})"
)
</template>

<script>
export default {
  name: 'talent-search',
  data() {
    return {
      form: {
        name: '',
      },
    }
  },
}
</script>
